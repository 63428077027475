import { makeStyles } from '@material-ui/core';
import React from 'react';
import MCBO from '../../../assets/MBOLogo.png';
import WOOD from '../../../assets/wood.png';
import { PollResult } from '../../../polls/hooks/useFetchPollResult';

interface Props {
    pollResult: PollResult;
}

const VoteComponent = (props: Props) => {

    const classes = useStyles();
    const { pollResult } = props;

    return (
        <div className={classes.root}>
            <div className={classes.rowContainer}>
                <div className={classes.colContainer}>
                    {pollResult.votes.map((vote) => (
                        <div className={classes.cardContainer}>
                            <img className={classes.cardImage} src={vote.image_url} alt="card" />
                            <div className={classes.rowContainer}>
                                <div className={classes.colContainer}>
                                    <p className={classes.cardTextName}>{vote.value}</p>
                                    <p className={classes.cardTextPercent}>{vote.percentage}%</p>
                                </div>
                                <div className={classes.colContainer}>
                                    <p className={classes.cardTextVotes}>{vote.vote_count}</p>
                                </div>
                            </div>
                            <div className={classes.voteInstructions}>
                                <span className={classes.voteInstructionsText}>TYPE !{vote.option} TO VOTE FOR {vote.value}</span>
                            </div>
                        </div>
                    ))};
                </div>
                <div className={classes.rhsColContainer}>
                    <img className={classes.imageLogo} src={MCBO} alt="logo" />
                    <div className={classes.videoFrame}></div>
                </div>
            </div>
        </div>
    );
};

const useStyles = makeStyles({
    root: {
        width: '3840px',
        height: '2160px',
        color: '#132F25',
        backgroundImage: "url(" + WOOD + ")"
    },
    rowContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    colContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    rhsColContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '2980px',
        height: "2160px",
        justifyContent: 'center',
        alignItems: 'center',
    },
    cardContainer: {
        width: '796px',
        background: 'linear-gradient(180deg, #FAB92D 0%, #FE6C47 100%)',
        marginTop: '32px',
        marginLeft: '32px',
        flexDirection: 'column'
    },
    cardImage: {
        marginTop: '24px',
        marginLeft: '24px',
        marginRight: '24px',
        marginBottom: '0px',
        width: '748px',
        height: '748px',
        objectFit: 'cover',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    },
    cardTextName: {
        margin: '0',
        marginLeft: "24px",
        fontSize: '46px',
        width: '574px',

    },
    cardTextPercent: {
        margin: '0',
        marginLeft: "24px",
        fontSize: '78px'
    },
    cardTextVotes: {
        margin: '0',
        marginRight: "24px",
        fontSize: '46px',
        width: '174px',
        textAlign: 'right',
    },
    voteInstructions: {
        backgroundColor: '#132F25',
        height: "71px",
        display: 'flex',
    },
    voteInstructionsText: {
        color: "#E7BC10",
        marginLeft: "24px",
        fontSize: "24px",
        marginTop: "22px",
    },
    videoFrame: {
        marginTop: '32px',
        width: '2655px',
        height: '1495px',
        background: 'linear-gradient(180deg, #FAB92D 0%, #FE6C47 100%)',
    },
    imageLogo: {
        width: '2661px',
    }




});

export default VoteComponent;
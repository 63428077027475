import { Button, CircularProgress } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React, { useState } from "react";
import Pagination from "../../components/Pagination";
import { THIRTY_SECONDS } from "../constants";
import { DonationResponse, DonationType, FilterParams, useFetchDonations } from "../hooks/useFetchDonations";

export default function SimpleTable(props: { donationStatus: string }) {
  const { donationStatus } = props;
  const classes = useStyles();

  const [donationResults, setDonationResults] = useState<DonationResponse>();
  const [filterParams, setFilterParams] = useState<FilterParams>({
    page: 1,
    pageSize: 10,
    status: donationStatus,
    sortOrder: "desc",
    type: DonationType.IMAGE,
  });

  useFetchDonations(filterParams, {
    refetchInterval: THIRTY_SECONDS,
    onSuccess: (response) => setDonationResults(response),
  });

  if (!donationResults) {
    return <CircularProgress />;
  }

  const handleOpen = (token: string) => () => {
    window.open(`/printerdonations/${token}`, "_blank");
  };

  const leftArrowClick = () => {
    const previousPage = Math.max((filterParams.page -= 1), 1);
    setFilterParams({ ...filterParams, page: previousPage });
  };

  const rightArrowClick = () => {
    const nextPage = Math.min((filterParams.page += 1), donationResults.total);
    setFilterParams({ ...filterParams, page: nextPage });
  };

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align="right">Message</TableCell>
            <TableCell align="right">Donation</TableCell>
            <TableCell align="right">Image</TableCell>
            <TableCell align="right">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {donationResults.donations.map((row) => (
            <TableRow key={row.id}>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.message}</TableCell>
              <TableCell align="right">{row.donation}</TableCell>
              <TableCell align="right">{row.media}</TableCell>
              <TableCell align="right">
                <Button onClick={handleOpen(row.token)} color="primary" variant="contained">
                  Open
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Pagination
        currentPage={filterParams.page}
        totalPages={donationResults.total}
        leftArrowClick={leftArrowClick}
        rightArrowClick={rightArrowClick}
      />
    </TableContainer>
  );
}

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import { styled } from '@material-ui/styles';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import configuration from '../../../configuration';


export interface PollOption {
    option: number;
    value: string;
    imageId?: number;
}
interface IProps {
    number: number;
    colorPrimary: string;
    colorSecondary: string;
    value?: PollOption;
    onChange?: (response: PollOption) => void;
}

export type ImageUploadResponse = {
    id: number;
    url: string;
}

const Root = styled('div')({
    width: '100%',
    height: '120px',
    color: '#000',
    display: 'flex',
    flexDirection: 'row',
    border: '1px solid #E0E2E4',
    borderRadius: '20px',
    marginBottom: '24px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',

});

const SubContainer = styled('div')({
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '24px',
});

const FormContainer = styled('div')({
    width: '100%',
    display: 'grid',
    gridTemplateRows: '1fr',
    gridTemplateColumns: '1fr 1fr',
    gridGap: '24px',
});

const NumberContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '70px',
    width: '120px',
    borderRadius: '20px',
    boxShadow: '4px 0px 4px rgba(0, 0, 0, 0.25)',
});

const ImageContainer = styled('div')({
    width: '120px',
    flexDirection: 'row',
    display: 'flex',
});

const ResponseSetupComponent = (props: IProps) => {
    const { colorPrimary, colorSecondary, number, onChange } = props;
    const fileUpload = React.createRef<HTMLInputElement>();
    const [image, setImage] = useState<string>();
    const [drag, setDrag] = useState<boolean>(false);
    const [value, setValue] = useState<string>('');
    const [imageId, setImageId] = useState<number>();

    useEffect(() => {
        if (onChange) {
            onChange({ option: number, imageId, value });
        }
    }, [imageId, value, onChange, number]);

    const uploadImage = async (image: Blob) => {
        const formData = new FormData();
        formData.append('file', image);
        const { data } = await axios.post<ImageUploadResponse>(`${configuration.apiUrl}/polls/polls/upload_image`, formData);
        return data;
    }

    const handleFileClick = () => {
        fileUpload.current?.click();
    };

    const handleFileUpload = async (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (event.currentTarget.files && event.currentTarget.files.length > 0) {
            const file: File = event.currentTarget.files[0];
            setImage(URL.createObjectURL(file));
            const data = await uploadImage(file);
            setImageId(data.id);
        }
    };

    const handleDragIn = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setDrag(true);
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleDragOut = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setDrag(false);
    };

    const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
            const file: File = event.dataTransfer.files[0];
            setImage(URL.createObjectURL(file));
            await uploadImage(file);
        }
    };

    return (
        <Root style={{ backgroundColor: colorSecondary }} >
            <NumberContainer style={{ backgroundColor: colorPrimary }} >
                {props.number}
            </NumberContainer>
            <SubContainer>
                <FormContainer>
                    <TextField
                        fullWidth
                        label="Value"
                        variant="outlined"
                        helperText={'Keep this short 15 chars'}
                        inputProps={{ maxLength: 15 }}
                        onChange={(e) => setValue(e.target.value)}
                        value={value}
                    />
                    <Input
                        onDrop={handleDrop}
                        onDragOver={handleDragOver}
                        onDragEnter={handleDragIn}
                        onDragExit={handleDragOut}
                        onClick={handleFileClick}
                        onChange={handleFileUpload}
                        type="file"
                    />
                </FormContainer>
                <ImageContainer>
                    {!image && !drag && "Drag and drop an image"}
                    {!image && drag && "DROP HERE!"}
                    {image && <img style={{
                        height: '120px',
                        width: '120px',
                        objectFit: 'contain',
                    }} src={image} alt="upload-file" />}
                </ImageContainer>
            </SubContainer>
        </Root>
    );
};

export default ResponseSetupComponent;
import { CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import React, { useState } from 'react';
import Pagination from '../../components/Pagination';
import { THIRTY_SECONDS } from '../constants';
import { DonationResponse, DonationType, FilterParams, useFetchDonations } from '../hooks/useFetchDonations';

const Pending = () => {
    const [doneDonations, setDoneDonations] = useState<DonationResponse>();
    const [filterParams, setFilterParams] = useState<FilterParams>({
        page: 1,
        pageSize: 10,
        mailbackStatus: 'Done',
        sortOrder: 'desc',
        type: DonationType.IMAGE,
    })

    useFetchDonations(filterParams, {
        refetchInterval: THIRTY_SECONDS,
        onSuccess: (response) => setDoneDonations(response),
    });

    if (!doneDonations) {
        return <CircularProgress />
    }

    const { donations, total } = doneDonations;

    const leftArrowClick = () => {
        const previousPage = Math.max(filterParams.page -= 1, 1);
        setFilterParams({ ...filterParams, page: previousPage });
    }

    const rightArrowClick = () => {
        const nextPage = Math.min(filterParams.page += 1, total);
        setFilterParams({ ...filterParams, page: nextPage });
    }

    return (
        <div>
            <h2>Done</h2>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell align="right">Message</TableCell>
                            <TableCell align="right">Donation</TableCell>
                            <TableCell align="right">Image</TableCell>
                            <TableCell align="right">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {donations.map((row, idx) => (
                            <TableRow key={row.id}>

                                <TableCell>{row.id}</TableCell>
                                <TableCell>{row.name}</TableCell>
                                <TableCell align="right">{row.message}</TableCell>
                                <TableCell align="right">{row.donation}</TableCell>
                                <TableCell align="right">{row.media}</TableCell>
                                <TableCell align="right">{row.action}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Pagination
                currentPage={filterParams.page}
                totalPages={total}
                leftArrowClick={leftArrowClick}
                rightArrowClick={rightArrowClick}
            />
        </div>

    );
}

export default Pending;
import { makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import LOADINGIMAGE from "../../assets/loading.png";
import WOOD from "../../assets/wood.png";
import { PollResult, useFetchPollResult } from "../../polls/hooks/useFetchPollResult";

const VersusOverlay = () => {
  const vsLogo = require("../../assets/vs.png");
  const classes = useStyles();

  const [pollResult, setPollResult] = useState<PollResult>();

  useFetchPollResult({
    refetchInterval: 2000,
    onSuccess: (response) => {
      setPollResult(response);
    },
  });

  if (pollResult === undefined) {
    return (
      <div className={classes.rootLoading}>
        <img className={classes.loadingImage} src={LOADINGIMAGE} alt="loading" />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.fullContainer}>
        <img className={classes.vsLogo} src={vsLogo} alt="logo" />
        {pollResult.votes.map((vote) => (
          <div className={classes.contentContainer}>
            <div className={classes.cardContainer}>
              <img className={classes.cardImage} src={vote.image_url} alt="versus" />
              <div className={classes.nameContainer}>
                <h1 className={classes.nameText}>{vote.value}</h1>
              </div>
            </div>
          </div>
        ))}
        ;
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  rootLoading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "3840px",
    height: "2160px",
    color: "#132F25",
    backgroundImage: "url(" + WOOD + ")",
  },
  loadingImage: {
    width: "100%",
  },
  root: {
    width: "3840px",
    height: "2160px",
    color: "#132F25",
  },
  videoTag: {
    position: "fixed",
    width: "3840px",
    zIndex: -1,
  },
  fullContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "100%",
  },
  contentContainer: {
    width: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cardContainer: {
    width: "1407px",
    height: "1407px",
    background: "linear-gradient(180deg, #FAB92D 0%, #FE6C47 100%)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  cardImage: {
    width: "1359px",
    height: "1359px",
    objectFit: "cover",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    marginTop: "24px",
  },
  vsLogo: {
    position: "fixed",
    zIndex: 1,
    top: 570,
    left: 1264,
    width: "1200px",
  },
  nameContainer: {
    width: "100%",
    height: "200px",
    background: "linear-gradient(180deg, #FAB92D 0%, #FE6C47 100%)",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  nameText: {
    margin: 0,
    textAlign: "center",
    fontSize: "118px",
    color: "#132F25",
  },
});

export default VersusOverlay;

import axios from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import configuration from '../configuration';
import { CommunityBoardStatus } from './useUpdateCommunityBoard';


export interface CommunityBoardRequest {
    status: CommunityBoardStatus;
}
export interface Ad {
    contactName: string;
    contactPhone: string;
    description: string;
    id: number;
    imageUrl: string;
    itemName: string;
    price: number;
    status: CommunityBoardStatus;
}

async function fetchCommunityBoard(params: CommunityBoardRequest) {
    const { data } = await axios.get<Ad[]>(`${configuration.apiUrl}/community-board`, { params });
    return data;
}  

export function useCommunityBoard(
    params: CommunityBoardRequest,
    options?: UseQueryOptions<Ad[]>,
){
    return useQuery<Ad[]>(['community-board', params], () => fetchCommunityBoard(params), options);
}
import { useAuth0 } from '@auth0/auth0-react';
import axios from "axios";
import { useQuery, UseQueryOptions } from "react-query";
import configuration from "../../configuration";
import { PollStatus } from './useFetchPolls';

export interface Vote {
    option: number;
    value: string;
    vote_count: number;
    percentage: number;
    image_url: string;
}

export interface PollResult {
    question: string;
    end_time: number;
    status: PollStatus;
    votes: Vote[];
}


export function useFetchPollResult(
    options?: UseQueryOptions<PollResult>
) {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery<PollResult>('poll-result',
        async () => {
            const token = await getAccessTokenSilently();
            const { data } = await axios.get<PollResult>(
                `${configuration.apiUrl}/polls/polls/result`,
                {
                    headers: {
                        'authorization': `Bearer ${token}`
                    }
                });
            return data;
        },
        options);
}
import React, { useEffect, useState } from "react";
import { makeStyles, Button, ButtonGroup } from "@material-ui/core";
import axios from "axios";
import configuration from "../../configuration";

interface Response {
  amount: number;
}

const AdminScreen = () => {
  const classes = useStyles();
  const lowdonation = 250;
  const mediumdonation = 500;
  const highdonation = 1000;

  const [total, setTotal] = useState<number>(0);

  useEffect(() => {
    axios.get<Response>(`${configuration.apiUrl}/polls/donations`).then(({ data }) => {
      setTotal(data.amount);
    });
  }, []);

  const reset = async () => {
    const { data } = await axios.delete<Response>(`${configuration.apiUrl}/polls/donations`);
    setTotal(data.amount);
  }

  const donate = (amount: number) => async () => {
    const donation = {
      amount,
    }

    const { data } = await axios.post<Response>(`${configuration.apiUrl}/polls/donations`, donation);
    setTotal(data.amount);
  }

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <h1 className={classes.cashtotal}>${total}</h1>
        <div className={classes.butongroup}>
          <Button onClick={reset} size="large" variant="contained" fullWidth color="secondary">
            RESET
          </Button>
        </div>
        <ButtonGroup
          size="large"
          color="primary"
          orientation="vertical"
          variant="contained"
          fullWidth
        >
          <Button onClick={donate(lowdonation)} >{lowdonation}</Button>
          <Button onClick={donate(mediumdonation)} >{mediumdonation}</Button>
          <Button onClick={donate(highdonation)} >{highdonation}</Button>
        </ButtonGroup>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "90vw",
    height: "100vh",
  },
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  cashtotal: {
    marginBottom: "80px",
    fontSize: "80px",
  },
  butongroup: {
    marginBottom: "300px",
    width: "100%",
  },
});

export default AdminScreen;

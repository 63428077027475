import { Button, makeStyles, Snackbar } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Controller, NestedValue, SubmitHandler, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import configuration from '../../configuration';
import ResponseSetupComponent, { PollOption } from './components/ResponseSetupComponent';


export interface IForm {
    video_id: string;
    question: string;
    poll_options: NestedValue<PollOption[]>;
}

const PollCreator = () => {
    const classes = useStyles();
    const { control, handleSubmit, reset, setValue } = useForm<IForm>({
        defaultValues: {
            video_id: '',
            question: '',
            poll_options: [],
        }
    });
    const [openAlert, setOpenAlert] = useState<boolean>(false);

    useEffect(() => {
        const videoId = localStorage.getItem("videoId");
        if (!!videoId) {
            setValue('video_id', videoId);
        }
    }, [setValue]);

    const onSubmit: SubmitHandler<IForm> = (data) => {
        const pollOptions = data.poll_options.filter((d) => d.value);

        localStorage.setItem("videoId", data.video_id);

        axios.post(`${configuration.apiUrl}/polls/polls`, {
            video_id: data.video_id,
            question: data.question,
            poll_options: pollOptions,
        }).then(() => {
            setOpenAlert(true);
            reset();
            setValue('video_id', data.video_id);
        });
    };

    const voteOptions = [
        { id: 1, colorPrimary: '#72E6FF', colorSecondary: '#DEF9FF' },
        { id: 2, colorPrimary: '#FF0000', colorSecondary: '#FFC5C5' },
        { id: 3, colorPrimary: '#EE00FF', colorSecondary: '#FCD3FF' },
        { id: 4, colorPrimary: '#CBFF00', colorSecondary: '#EFFFB1' },
    ]

    return (
        <div className={classes.root}>

            <form onSubmit={handleSubmit(onSubmit)} >
                <div className={classes.container}>
                    <h1>YOUTUBE POLL CREATOR</h1>
                    <div>
                        <Button component={Link} to={'/polls'} variant="outlined" color="primary" >View Polls</Button>
                    </div>

                </div>


                <div className={classes.ytContainer}>
                    <Controller
                        control={control}
                        name="video_id"
                        rules={{
                            required: true,
                        }}
                        render={({ field }) => <TextField
                            {...field}
                            autoComplete="off"
                            label="Youtube ID"
                            variant="outlined"
                            helperText={'Get this motherfucker from the URL'}
                        />}
                    />

                </div>
                <div className={classes.questionContainer}>
                    <Controller
                        control={control}
                        name="question"
                        rules={{
                            required: true,
                        }}
                        render={({ field }) => <TextField
                            {...field}
                            fullWidth
                            autoComplete="off   "
                            label="Poll Question"
                            variant="outlined"
                            helperText={'This is what you wanna poll the viewers'}
                        />}
                    />

                </div>
                {voteOptions.map((option) => (
                    <Controller
                        key={option.id}
                        name={`poll_options.${option.id - 1}`}
                        control={control}
                        render={({ field }) => <ResponseSetupComponent
                            onChange={field.onChange}
                            key={option.id}
                            number={option.id}
                            colorPrimary={option.colorPrimary}
                            colorSecondary={option.colorSecondary}
                        />
                        }
                    />
                ))}
                <div className={classes.buttonContainer}>
                    <Button
                        fullWidth type="submit"
                        variant="contained"
                        color="primary"
                    >
                        Create Poll
                    </Button>
                </div>
                <Snackbar
                    open={openAlert}
                    onClose={() => setOpenAlert(false)}
                    autoHideDuration={3000}
                    message="Poll has been created!"
                />
            </form>
        </div>
    );
};


const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: 'column',
        width: "1000px",
        margin: '24px',
    },
    ytContainer: {
        width: '300px',
        marginBottom: '24px',
    },
    questionContainer: {
        width: '800px',
        marginBottom: '24px',
    },
    buttonContainer: {
        marginTop: '48px',
    },
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '16px',
    },
});

export default PollCreator; 
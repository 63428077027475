import { makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Ad, useCommunityBoard } from "../useCommunityBoard";

const CommunityBoardOverlay = () => {
  const [payload, setPayload] = useState<Ad[]>([]);
  const classes = useStyles();

  useCommunityBoard(
    { status: "APPROVED" },
    {
      onSuccess: (data) => setPayload(data),
      refetchInterval: 10000,
    }
  );

  return (
    <Carousel
      infiniteLoop
      interval={13000}
      showStatus={false}
      showThumbs={false}
      showIndicators={false}
      showArrows={false}
      autoFocus
      autoPlay={true}
      width={880}
      useKeyboardArrows
      swipeable
      emulateTouch
      transitionTime={0}
    >
      {payload.map((item) => (
        <div className={classes.root} key={item.id}>
          <div className={classes.container}>
            <img className={classes.image} src={item.imageUrl} alt="item" />
            <div className={classes.textContainer}>
              <div className={classes.textContainerTitleArea}>
                <h1 className={classes.titleText}>{item.itemName}</h1>
                <h1 className={classes.titleText}>{item.price === 0 ? "Free" : `$${item.price}`}</h1>
              </div>
              <div className={classes.textContainerBodyArea}>
                <p className={classes.bodyText}>{item.description}</p>
                <p className={classes.bodyBoldText}>{`Contact ${item.contactName} @ ${item.contactPhone}`}</p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </Carousel>
  );
};

const useStyles = makeStyles({
  root: {
    display: "flex",
    fontFamily: "VT323",
  },

  container: {
    width: "880px",
    height: "960px",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
  },
  image: {
    width: "100%",
    objectFit: "cover",
    aspectRatio: "5/4",
  },
  textContainer: {
    padding: "24px",
  },
  textContainerTitleArea: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },

  textContainerBodyArea: {
    textAlign: "left",
  },

  imageTapePlayer: {
    borderRadius: "20px",
    filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
  },

  titleText: {
    margin: 0,
    fontSize: "50px",
    textTransform: "uppercase",
  },
  bodyBoldText: {
    fontWeight: 800,
    textTransform: "uppercase",
    fontSize: "30px",
  },
  bodyText: {
    fontSize: "30px",
    textTransform: "uppercase",
  },
});

export default CommunityBoardOverlay;

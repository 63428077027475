import { Button, CircularProgress } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React, { useRef, useState } from "react";
import Pagination from "../../components/Pagination";
import { THIRTY_SECONDS } from '../constants';
import { DonationResponse, DonationType, FilterParams, useFetchDonations } from "../hooks/useFetchDonations";

import { useQueueAudio } from './hooks/useQueueAudio';

interface Props {
  donationStatus: string;
}

export default function SimpleTable(props: Props) {
  const classes = useStyles();
  const audio = useRef<HTMLAudioElement>(new Audio());
  const [playingAudio, setPlayingAudio] = useState<number>(0);

  const [donationResults, setDonationResults] = useState<DonationResponse>();
  const [filterParams, setFilterParams] = useState<FilterParams>({
    page: 1,
    pageSize: 10,
    status: props.donationStatus,
    sortOrder: "desc",
    type: DonationType.AUDIO,
  });

  const queueAudioMutation = useQueueAudio();


  useFetchDonations(filterParams, {
    refetchInterval: THIRTY_SECONDS,
    onSuccess: (response) => setDonationResults(response),
  });


  const leftArrowClick = () => {
    const previousPage = Math.max((filterParams.page -= 1), 1);
    setFilterParams({ ...filterParams, page: previousPage });
  };

  const rightArrowClick = () => {
    if (donationResults) {
      const nextPage = Math.min((filterParams.page += 1), donationResults.total);
      setFilterParams({ ...filterParams, page: nextPage });

    }
  };

  const handlePlay = (id: number) => () => {
    if (donationResults) {
      const donation = donationResults.donations.find((d) => d.id === id);
      if (donation) {
        if (audio.current.paused) {
          audio.current.src = donation.url;
          audio.current.play();
          setPlayingAudio(donation.id);
          audio.current.onpause = () => {
            audio.current.currentTime = 0;
            setPlayingAudio(0);
          };
        } else {
          audio.current.pause();
        }
      }
    }
  };

  const handleQueue = (id: number) => async () => {
    queueAudioMutation.mutate(id);
  };

  if (!donationResults) {
    return <CircularProgress />;
  }


  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align="right">Message</TableCell>
            <TableCell align="right">Donation</TableCell>
            <TableCell align="right">Image</TableCell>
            <TableCell align="right">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {donationResults.donations.map((row) => (
            <TableRow key={row.id}>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.message}</TableCell>
              <TableCell align="right">{row.donation}</TableCell>
              <TableCell align="right">{row.media}</TableCell>
              <TableCell align="right">
                <Button onClick={handlePlay(row.id)} color="primary" variant="contained">
                  {playingAudio === row.id ? "STOP" : "PREVIEW"}
                </Button>
                <Button
                  style={{ marginLeft: "8px" }}
                  onClick={handleQueue(row.id)}
                  color="secondary"
                  variant="contained"
                >
                  PLAY LIVE
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Pagination
        currentPage={filterParams.page}
        totalPages={donationResults.total}
        leftArrowClick={leftArrowClick}
        rightArrowClick={rightArrowClick}
      />
    </TableContainer>
  );
}

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

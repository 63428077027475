import React, { useState } from "react";
import DonationTable from "./table";
import HistoryTable from "./historyTable";
import { Tabs, Tab } from "@material-ui/core";

export const THIRTY_SECONDS = 1000 * 30;

const Admin = () => {
  const [tab, setTab] = useState<number>(0);

  const handleTabChange = (event: React.ChangeEvent<{}>, value: number) => {
    setTab(value);
  };

  return (
    <div>
      <Tabs value={tab} onChange={handleTabChange} style={{ marginBottom: "24px" }}>
        <Tab label="Pending" />
        <Tab label="Printed" />
        <Tab label="Rejected" />
      </Tabs>
      {tab === 0 && (
        <>
          <DonationTable />
        </>
      )}
      {tab === 1 && (
        <>
          <HistoryTable donationStatus="Printed" />
        </>
      )}
      {tab === 2 && (
        <>
          <HistoryTable donationStatus="Rejected" />
        </>
      )}
    </div>
  );
};

export default Admin;

import { makeStyles, Slider, Tab, Tabs } from "@material-ui/core";
import React, { useState } from "react";
import { useQueryClient } from 'react-query';
import HistoryTable from "./historyTable";
import { useGetAudioVolume } from "./hooks/useGetAudioVolume";
import { useSetAudioVolume } from "./hooks/useSetAudioVolume";
import DonationTable from "./table";

const Admin = () => {
  const [tab, setTab] = useState<number>(0);
  const [volume, setVolume] = useState<number>(0.5);
  const classes = useStyles();

  const queryClient = useQueryClient();

  useGetAudioVolume({
    onSuccess: (response) => {
      setVolume(response);
    }
  })

  const audioVolumeMutation = useSetAudioVolume({
    onSuccess: () => {
      queryClient.invalidateQueries('audio-volume');
    }
  })

  const handleVolumeChange = async (event: React.ChangeEvent<{}>, value: number | number[]) => {
    if (Array.isArray(value)) {
      value = value[0];
    }
    audioVolumeMutation.mutate(value);
  }


  const handleTabChange = (event: React.ChangeEvent<{}>, value: number) => {
    setTab(value);
  };

  return (
    <div>
      <div className={classes.sliderContainer}>
        <Slider
          value={volume}
          step={0.1}
          min={0}
          max={1}
          valueLabelDisplay="on"
          onChange={handleVolumeChange}
          disabled
        />
      </div>
      <Tabs value={tab} onChange={handleTabChange} style={{ marginBottom: "24px" }}>
        <Tab label="Pending" />
        <Tab label="Printed" />
        <Tab label="Rejected" />
      </Tabs>
      {tab === 0 && (
        <>
          <DonationTable />
        </>
      )}
      {tab === 1 && (
        <>
          <HistoryTable donationStatus="Printed" />
        </>
      )}
      {tab === 2 && (
        <>
          <HistoryTable donationStatus="Rejected" />
        </>
      )}
    </div>
  );
};

const useStyles = makeStyles({
  sliderContainer: {
    // display: "flex",
    justifyContent: "center",
    alignContent: "center",
    display: "none",
    "&> *": {
      width: "500px",
    },
  },
});

export default Admin;

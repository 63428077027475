import { makeStyles, Tab, Tabs } from "@material-ui/core";
import React, { useState } from "react";
import { Ad, CommunityBoardRequest, useCommunityBoard } from "../useCommunityBoard";
import AdController from "./components/AdController";

const CommunityDashboard = () => {
  const classes = useStyles();
  const [tab, setTab] = useState<number>(0);
  const [payload, setPayload] = useState<Ad[]>([]);
  const handleTabChange = (event: React.ChangeEvent<{}>, value: number) => {
    setTab(value);
  };

  const [query, setQuery] = useState<CommunityBoardRequest>({ status: "PENDING" });

  useCommunityBoard(query, {
    onSuccess: (data) => setPayload(data),
    refetchInterval: 10000,
  });

  return (
    <div className={classes.container}>
      <Tabs value={tab} onChange={handleTabChange} style={{ marginBottom: "24px" }}>
        <Tab label="Pending" onClick={() => setQuery({ status: "PENDING" })} />
        <Tab label="Approved" onClick={() => setQuery({ status: "APPROVED" })} />
        <Tab label="Suspended" onClick={() => setQuery({ status: "SUSPENDED" })} />
        <Tab label="Rejected" onClick={() => setQuery({ status: "REJECTED" })} />
      </Tabs>
      {tab === 0 && <></>}
      {tab === 1 && <></>}
      {tab === 2 && <></>} {tab === 3 && <></>}
      <div className={classes.grid}>
        {payload.map((item) => (
          <AdController ad={item} key={item.id} />
        ))}
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "94vh",
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
    gridGap: "48px",
    marginBottom: "32px",
    width: "100%",
    overflowY: "scroll",
    overflowX: "hidden",
    height: "94vh",
  },
});

export default CommunityDashboard;

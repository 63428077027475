import axios from "axios";
import { useQuery, UseQueryOptions } from "react-query";
import configuration from '../configuration';


export interface Credit {
    name: string;
    tier: number;
}

export function useFetchCredits(    
    options?: UseQueryOptions<Credit[]>
) {    
    return useQuery<Credit[]>('credits',
        async () => {            
            const { data } = await axios.get<Credit[]>(`${configuration.apiUrl}/patreon/members`);
            return data;
        },
        options);
}
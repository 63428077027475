import { useAuth0 } from '@auth0/auth0-react';
import axios, { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import configuration from '../../configuration';

interface SetReadyRequest {
    pollId: number;
}

export function useSetReady(
    options?: Omit<
    UseMutationOptions<
      void,
      AxiosError,
      SetReadyRequest
    >,
    'mutationKey' | 'mutationFn'
  >,
) {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(
        async (req: SetReadyRequest) => {            
            const token = await getAccessTokenSilently();
            axios.post(`${configuration.apiUrl}/polls/polls/${req.pollId}/ready`, null, {
                headers: {
                    'authorization': `bearer ${token}`
                }
            });

        }, options
    );
}
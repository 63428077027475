import { makeStyles } from '@material-ui/core';
import React from 'react';
import { Vote } from '../../../hooks/useFetchPollResult';


interface Props {
    vote: Vote;
}

const TextVoteComponent = (props: Props) => {

    const classes = useStyles();
    const { vote } = props;

    return (
        <div className={classes.root}>
            <div className={classes.responseContainer}>
                <p className={classes.valueText}>{vote.value}</p>
                <div className={classes.imageFrame}>
                    <div className={classes.metaBox}>
                        <div className={classes.numberBox}>
                            <p className={classes.optionText}>!{vote.option}</p>
                        </div>
                        <div className={classes.votesBox}>
                            <p className={classes.votesText}>Votes: {vote.vote_count}</p>
                        </div>
                    </div>
                </div>
            </div>
            <p className={classes.questionText}>{props.vote.percentage}%</p>
        </div>

    )
}

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'rgba(0, 0, 0, 0.4)',
        border: '1px solid #E7BD10',
    },
    blackBarResponses: {
        width: '3840px',
        height: '1006px',
        background: 'rgba(0, 0, 0, 0.4)',
        display: 'flex',
        flexDirection: 'row',
        position: 'absolute',
        bottom: 0,
        justifyContent: 'center',
    },

    blackBarQuestion: {
        width: '100%',
        height: '220px',
        background: 'rgba(0, 0, 0, 0.4)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },

    responseContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '959px',
        justifyContent: 'center',
        alignItems: 'center',
    },
    metaBox: {
        width: '100%',
        height: '95px',
        position: 'absolute',
        bottom: 0,
        display: 'flex',
        flexDirection: 'row',
        background: 'rgba(0, 0, 0, 0.7)',
    },
    numberBox: {
        display: 'flex',
        backgroundColor: '#E7BD10',
        width: '119px',
        justifyContent: 'center',
        alignItems: 'center',

    },
    votesBox: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    imageFrame: {
        height: '95px',
        width: '652px',
        display: 'flex',
        flexDirection: 'column',
        background: 'rgba(0, 0, 0, 0.4)',
        justifyContent: 'center',
        alignItems: 'center',
        filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
    },

    questionText: {
        textAlign: 'center',
        fontSize: '107px',
        color: '#E7BD10',
        margin: 0,
        textTransform: 'uppercase',
        fontFamily: 'Open Sans',
    },
    valueText: {
        textAlign: 'center',
        fontSize: '107px',
        color: '#E7BD10',
        margin: '0',
        marginBottom: '24px',
        textTransform: 'uppercase',
        fontFamily: 'Open Sans',
    },
    optionText: {
        textAlign: 'center',
        fontSize: '66px',
        margin: '0',
        textTransform: 'uppercase',
        fontFamily: 'Open Sans',
    },
    votesText: {
        textAlign: 'center',
        fontSize: '40px',
        margin: '0',
        fontFamily: 'Open Sans',
        color: '#E7BD10',
    },



});
export default TextVoteComponent;
import {
  Button,
  Checkbox,
  CircularProgress,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { useState } from "react";
import { useQueryClient } from "react-query";
import { THIRTY_SECONDS } from "../constants";
import { useExportCSV } from "../hooks/useExportCsv";
import { DonationResponse, DonationType, FilterParams, useFetchDonations } from "../hooks/useFetchDonations";
import { useMailbackDone } from "../hooks/useMailbackDone";

const Pending = () => {
  const classes = useStyles();
  const [pendingDonations, setPendingDonations] = useState<DonationResponse>();
  const [filterParams] = useState<FilterParams>({
    page: 1,
    mailbackStatus: "Pending",
    sortOrder: "asc",
    type: DonationType.IMAGE,
  });

  const queryClient = useQueryClient();

  useFetchDonations(filterParams, {
    refetchInterval: THIRTY_SECONDS,
    onSuccess: (response) => setPendingDonations(response),
  });

  const mailbackDoneMutation = useMailbackDone({
    onSuccess: () => queryClient.invalidateQueries("donations"),
  });

  const exportCSVMutation = useExportCSV({
    onSuccess: (response) => {
      const blob = new Blob([response], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "test.csv";
      a.click();
    },
  });

  const markDone = () => {
    const doneDonations = pendingDonations?.donations.filter((d) => d.selected).map((d) => d.id);
    if (doneDonations) {
      mailbackDoneMutation.mutate(doneDonations);
    }
  };

  const exportCsv = () => {
    const csvExport = pendingDonations?.donations.filter((d) => d.selected).map((d) => d.id);
    if (csvExport) {
      exportCSVMutation.mutate(csvExport);
    }
  };

  if (!pendingDonations) {
    return <CircularProgress />;
  }

  const { donations } = pendingDonations;

  const handleOpen = (token: string) => () => {
    window.open(`/printerdonations/${token}`, "_blank");
  };

  const handleCheckboxClick = (id: number) => (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const updated = donations.map((p) => (p.id !== id ? p : { ...p, selected: checked }));
    setPendingDonations({ ...pendingDonations, donations: updated });
  };

  const selectAll = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const updated = donations.map((d) => ({ ...d, selected: checked }));
    setPendingDonations({ ...pendingDonations, donations: updated });
  };

  return (
    <div>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Checkbox onChange={selectAll} />
              </TableCell>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell align="right">Message</TableCell>
              <TableCell align="right">Donation</TableCell>
              <TableCell align="right">Image</TableCell>
              <TableCell align="right">Donated At</TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {donations.map((row) => (
              <TableRow key={row.id}>
                <TableCell>
                  <Checkbox onChange={handleCheckboxClick(row.id)} checked={row.selected || false} />
                </TableCell>
                <TableCell>{row.id}</TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell align="right">{row.message}</TableCell>
                <TableCell align="right">{row.donation}</TableCell>
                <TableCell align="right">{row.media}</TableCell>
                <TableCell align="right">{new Date(row.createdAt).toLocaleString("en-CA")}</TableCell>
                <TableCell align="right">
                  <Button onClick={handleOpen(row.token)} color="primary" variant="contained">
                    Open
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className={classes.btnGroup}>
        <div>
          <button onClick={exportCsv}>Generate CSV for Selected</button>
        </div>
        <div>
          <button onClick={markDone}>Mark Selected as Done</button>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  btnGroup: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    "& > div": {
      display: "flex",
      justifyContent: "flex-end",
      margin: "8px 0",
    },
  },
});

export default Pending;

import { makeStyles } from "@material-ui/core";
import React, { useCallback } from "react";
import { VIDEO_STATES } from "../..";

interface Props {
  videoUrl: string;
  videoState: VIDEO_STATES;
  setState: React.Dispatch<React.SetStateAction<VIDEO_STATES>>;
}

const VideoPlayer = (props: Props) => {
  const classes = useStyles();
  const { videoUrl, videoState, setState } = props;

  const videoCallback = useCallback(
    (videoElem: HTMLVideoElement) => {
      if (videoElem) {
        if (videoState === "ANSWER") {
          videoElem.play();
        } else {
          videoElem.pause();
        }
      }
    },
    [videoState]
  );

  const handleVideoPause = () => {
    setState("NEXT_VIDEO");
  };

  return (
    <div className={classes.root}>
      {videoState === "NEXT_VIDEO" ? null : (
        <video
          onPause={handleVideoPause}
          ref={videoCallback}
          src={videoUrl}
          style={{ width: "100%", height: "100vh" }}
        ></video>
      )}
    </div>
  );
};

const useStyles = makeStyles({
  root: {
    width: "100%",
    backgroundColor: "#292929",
  },
});

export default VideoPlayer;

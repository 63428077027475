import axios, { AxiosError } from "axios";
import { useMutation, UseMutationOptions } from "react-query";
import configuration from "../../configuration";

export interface GetShockResponse {
  name: string;
  power: number;
}


export function useZap(
  options?: Omit<
      UseMutationOptions<
          void,
          AxiosError,
          void
      >,
      'mutationKey' | 'mutationFn'
  >,
) {  
  return useMutation(
      async () => {            
          await axios.post<void>(`${configuration.apiUrl}/donations/zap`, null, {
          });          
      }, options
  );
}
import axios from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import configuration from '../../configuration';

export interface GetVideoResponse {
    url:string;
    name: string;
}

async function getVideo() {
    const { data } =  await axios.get<GetVideoResponse>(`${configuration.apiUrl}/donations/play_video`);
    return data;
}  

export function useGetVideo(    
    options?: UseQueryOptions<GetVideoResponse>,
){
    return useQuery<GetVideoResponse>(['play-video'], () => getVideo(), options);
}
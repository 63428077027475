import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import Axios from "axios";
import configuration from "../configuration";
import VoiceMessageOverlay from "./overlay";

const VoiceMessage = () => {
  const [hasDonation, setDonation] = useState<Boolean>();
  const [isReady, setReady] = useState<Boolean>();
  const [name, setName] = useState<string>("");

  const classes = useStyles();

  const DonationEvent = (duration: number) => {
    setDonation(true);
  };

  useEffect(() => {
    setDonation(false);
    setReady(false);
  }, []);

  useEffect(() => {
    setInterval(async () => {
      const { data } = await Axios.get<{ url: string; volume: number; name: string }>(
        `${configuration.apiUrl}/donations/play_audio`
      );

      if (data !== undefined && data.url !== undefined) {
        setName(data.name);
        const audio = new Audio(data.url);
        audio.volume = data.volume;
        audio.onloadedmetadata = function () {
          DonationEvent(audio.duration);
        };
        audio.play();
        audio.onpause = () => {
          setDonation(false);
          setName("");
        };
      }
    }, 3000);
  }, []);

  return (
    <div className={classes.root}>
      {isReady ? (
        ""
      ) : (
        <div className={classes.notReadyDiv}>
          <button className={classes.readyButton} onClick={() => setReady(true)}>
            🎤 SET VOICE MESSAGES READY 🎤
          </button>
        </div>
      )}

      {/* <div>{playingClip}</div> */}
      {hasDonation ? <VoiceMessageOverlay name={name} /> : ""}
    </div>
  );
};

const useStyles = makeStyles({
  root: {},
  readyButton: {
    padding: "100px",
    fontSize: "48px",
  },

  notReadyDiv: {
    backgroundColor: "green",
    height: "100vh",
    width: "100vw",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

export default VoiceMessage;

import { Button, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import { useArchivePolling } from './hooks/useArchivePolling';
import { useBeginPolling } from './hooks/useBeginPolling';
import { useCancelPolling } from './hooks/useCancelPolling';
import { Poll, PollStatus, useFetchPolls } from './hooks/useFetchPolls';
import { useSetReady } from './hooks/useSetReady';

const PollList = () => {

    const classes = useStyles();
    const [polls, setPolls] = useState<Poll[]>([]);

    useFetchPolls({
        refetchInterval: 3000,
        onSuccess(data: Poll[]): void {
            setPolls(data);
        },        
    });

    const queryClient = useQueryClient();

    const setReadyMutation = useSetReady({
        onSuccess: () => queryClient.invalidateQueries('polls')
    });

    const cancelPollingMutation = useCancelPolling({
        onSuccess: () => queryClient.invalidateQueries('polls')
    });

    const beginPollingMutation = useBeginPolling({
        onSuccess: () => queryClient.invalidateQueries('polls')
    });

    const archivePollingMutation = useArchivePolling({
        onSuccess: () => queryClient.invalidateQueries('polls')
    });

    const setReady = (pollId: number) => () => {
        setReadyMutation.mutate({ pollId });
    }

    const cancelPolling = (pollId: number) => () => {
        cancelPollingMutation.mutate({ pollId });
    }
    
    const archivePoll = (pollId: number) => () => {
        archivePollingMutation.mutate({ pollId });
    }

    const beginPolling = (pollId: number) => () => {
        beginPollingMutation.mutate({ pollId });
    }

    const actions = (poll: Poll) => {
        if (poll.status === PollStatus.QUEUE && polls.find((a) => a.status !== PollStatus.QUEUE && a.status !== PollStatus.ARCHIVE)) {
            return;
        }

        switch (poll.status) {
            case PollStatus.QUEUE:
                return <Button variant="outlined" color="primary" onClick={setReady(poll.id)} >Set to READY</Button>
            case PollStatus.READY:
                return <>
                    <Button variant="outlined" color="secondary" onClick={cancelPolling(poll.id)} >Cancel</Button>
                    <Button variant="outlined" color="primary" onClick={beginPolling(poll.id)} >Begin Polling</Button>
                </>
            case PollStatus.IN_PROGRESS:
                return <Button disabled variant="outlined" color="primary" >POLLING...</Button>
            case PollStatus.DONE:
                return <>
                    <Button variant="outlined" color="secondary" onClick={setReady(poll.id)} >Retry</Button>
                    <Button variant="outlined" color="primary" onClick={archivePoll(poll.id)} >Archive</Button>
                </>

            default:
                return;
        }
    }

    const timeLeft = (poll: Poll) => {
        if (poll.status === PollStatus.IN_PROGRESS && poll.started_at !== undefined) {
            return `${((poll.started_at + (poll.duration * 60)) - (Date.now() / 1000)).toFixed(0)} seconds left`;
        }

        return '';
    }

    return (
        <div >
            <div className={classes.container}>
                <Button component={Link} to={'/pollcreator'} variant="outlined" color="primary" >Add New Poll</Button>
            </div>
            <TableContainer component={Paper}>
                <Table className={classes.table} size="medium" aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Video ID</TableCell>
                            <TableCell>Question</TableCell>
                            <TableCell>Options</TableCell>
                            <TableCell>Timer</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {polls.map((poll) => (
                            <TableRow key={poll.id} selected={poll.status !== PollStatus.QUEUE} >
                                <TableCell>{poll.id}</TableCell>
                                <TableCell>{poll.video_id}</TableCell>
                                <TableCell>{poll.question}</TableCell>
                                <TableCell>{poll.poll_options.map(po => `[!${po.option} - ${po.value}]`).join(" | ")} </TableCell>
                                <TableCell>{timeLeft(poll)}</TableCell>
                                <TableCell>{poll.status}</TableCell>
                                <TableCell>{actions(poll)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )

};

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '16px',
    },
    table: {
        minWidth: 650,
    },
}));

export default PollList;
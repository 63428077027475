import { withAuthenticationRequired } from "@auth0/auth0-react";
import React, { ComponentType } from "react";
import LoadingImage from "../assets/loading.png";

const Loading = () => {
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#1b1537",
      }}
    >
      <img style={{ width: "25%" }} src={LoadingImage} alt="loading" />
    </div>
  );
};

export const ProtectedRoute = (props: { component: ComponentType }) => {
  const { component } = props;

  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => <Loading />,
  });

  return <Component />;
};

import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useQuery, UseQueryOptions } from "react-query";
import configuration from "../../configuration";

export enum DonationType {
  IMAGE = "image",
  AUDIO = "audio",
  VIDEO = "video",
}

export type FilterParams = {
  page: number;
  pageSize?: number;
  sortOrder: string;
  status?: string;
  mailbackStatus?: string;
  startDate?: string;
  endDate?: string;
  isFake?: boolean;
  type: DonationType;
};

export interface Donation {
  id: number;
  name: string;
  message: string;
  donation: number;
  media: string;
  url: string;
  action: string;
  createdAt: number;
  paymentId: string;
  selected?: boolean;
  status: string;
  token: string;
}
export interface DonationResponse {
  total: number;
  donations: Donation[];
}

export function useFetchDonations(filterParams: FilterParams, options?: UseQueryOptions<DonationResponse>) {
  const { getAccessTokenSilently } = useAuth0();
  return useQuery<DonationResponse>(
    ["donations", filterParams],
    async () => {
      const token = await getAccessTokenSilently();
      const { data } = await axios.get<DonationResponse>(`${configuration.apiUrl}/donations`, {
        params: filterParams,
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      return data;
    },
    options
  );
}

import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import axios from "axios";
import configuration from "../../configuration";

interface Response {
  amount: number;
}

const LiveDonationsOverlay = () => {
  const classes = useStyles();

  const [total, setTotal] = useState<number>(0);

  useEffect(() => {
    setInterval(() => {
      axios
        .get<Response>(`${configuration.apiUrl}/polls/donations`)
        .then(({ data }) => {
          setTotal(data.amount);
        });
    }, 3000);
  });

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <h1 className={classes.cashtotal}>${total}</h1>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100vw",
    height: "100vh",
  },
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#00000080",
  },
  cashtotal: {
    fontSize: "200px",
    color: "#e7bc10",
  },
});

export default LiveDonationsOverlay;

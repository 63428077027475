import { Auth0Provider } from '@auth0/auth0-react';
import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import config from "./auth_config.json";
import './index.css';
import * as serviceWorker from './serviceWorker';
import history from './utils/history';

const onRedirectCallback = (appState: any) => {
    history.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
};

const queryClient = new QueryClient()

ReactDOM.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
        <BrowserRouter>
            <Auth0Provider
                audience={config.audience}
                domain={config.domain}
                redirectUri={window.location.origin}
                onRedirectCallback={onRedirectCallback}
                clientId={config.clientId}                
            >
                <App />
            </Auth0Provider>
        </BrowserRouter>
        </QueryClientProvider>

    </React.StrictMode>
    , document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import axios, { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import configuration from '../configuration';

export type CommunityBoardStatus = 'APPROVED' | 'REJECTED' | 'PENDING' | 'SUSPENDED' | 'DELETED';
export interface UpdateAd {    
    contactName: string;
    contactPhone: string;
    description: string;    
    imageUrl: string;
    itemName: string;
    price: number;
    status: CommunityBoardStatus;
}

async function updateCommunityBoard(id: number, adUpdate: UpdateAd) {    
    await axios.post<UpdateAd>(`${configuration.apiUrl}/community-board/${id}`, adUpdate);    
}  

export function useUpdateCommunityBoard(
    id: number,
    options?: UseMutationOptions<void, AxiosError, UpdateAd>,
){
    return useMutation('community-board', (adUpdate) => updateCommunityBoard(id, adUpdate), options);
}
import axios from "axios";
import { useQuery, UseQueryOptions } from "react-query";
import configuration from "../../configuration";

export interface FetchDonationRequest {
  token: string;
}
export interface ViewerData {
  amount: number;
  createdAt: string;
  id: number;
  media?: any;
  message: string;
  name: string;
  paidAt?: any;
  paymentInfo?: any;
  paymentToken?: any;
  status: string;
  isMailback: boolean;
  token: string;
}

export function useFetchDonation(request: FetchDonationRequest, options?: UseQueryOptions<ViewerData>) {
  return useQuery<ViewerData>(
    ["donation", request],
    async () => {
      const { data } = await axios.get<ViewerData>(`${configuration.apiUrl}/donations/${request.token}`);
      return data;
    },
    options
  );
}

import { useAuth0 } from "@auth0/auth0-react";
import { Divider, makeStyles, Switch } from "@material-ui/core";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useFetchKeyValue, useSetKeyValue } from "../donations/hooks/useKeyValue";

const IS_ONLINE_KEY = "IS_ONLINE";
const SANDBOX_ENABLED = "SANDBOX_ENABLED";

const NavBar = () => {
  const classes = useStyles();
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const [activeSelection, setSelection] = useState("none");
  const [donationState, setDonationState] = useState<boolean>(false);
  const [sandboxState, setSandboxState] = useState<boolean>(false);
  const AnnualHandler = (e: string) => {
    setSelection(e);
  };

  const keyValueMutation = useSetKeyValue();

  useFetchKeyValue(
    { key: IS_ONLINE_KEY },
    {
      onSuccess: (response: boolean) => {
        if (response) {
          setDonationState(response);
        }
      },
    }
  );
  useFetchKeyValue(
    { key: SANDBOX_ENABLED },
    {
      onSuccess: (response: boolean) => {
        if (response) {
          setSandboxState(response);
        }
      },
    }
  );

  const switchLabel = { inputProps: { "aria-label": "Stream Status" } };

  const handleDonationStateChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    keyValueMutation.mutate(
      {
        key: IS_ONLINE_KEY,
        value: checked,
      },
      {
        onSuccess: () => {
          setDonationState(checked);
        },
      }
    );
  };

  const handleSandboxStateChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    keyValueMutation.mutate(
      {
        key: SANDBOX_ENABLED,
        value: checked,
      },
      {
        onSuccess: () => {
          setSandboxState(checked);
        },
      }
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.links}>
        <Link to={"/"}>
          <img
            alt=""
            src="https://cdn.shopify.com/s/files/1/0332/2819/3927/files/ShopBannerC_720x.png?v=1583818492"
            width={"100%"}
          />
        </Link>
        <Link to={"/donations/printer-dashboard"} onClick={() => AnnualHandler("printer-dashboard")}>
          <div className={activeSelection === "printer-dashboard" ? classes.active : classes.default}>
            <div className={classes.itemContainer}>
              <span>🖨️</span>
              <span> Printer Dashboard</span>
            </div>
          </div>
        </Link>
        <Divider style={{ background: "#6c6c6c" }} />
        <Link to={"/donations/audio-dashboard"} onClick={() => AnnualHandler("audio-dashboard")}>
          <div className={activeSelection === "audio-dashboard" ? classes.active : classes.default}>
            <div className={classes.itemContainer}>
              <span>📣</span>
              <span> Audio Dashboard</span>
            </div>
          </div>
        </Link>
        <Divider style={{ background: "#6c6c6c" }} />
        <Link to={"/donations/video-dashboard"} onClick={() => AnnualHandler("video-dashboard")}>
          <div className={activeSelection === "video-dashboard" ? classes.active : classes.default}>
            <div className={classes.itemContainer}>
              <span>📺</span>
              <span> Video Dashboard</span>
            </div>
          </div>
        </Link>
        <Divider style={{ background: "#6c6c6c" }} />
        <Link to={"/donations/community-dashboard"} onClick={() => AnnualHandler("community-dashboard")}>
          <div className={activeSelection === "community-dashboard" ? classes.active : classes.default}>
            <div className={classes.itemContainer}>
              <span>👚</span>
              <span> Community Dashboard</span>
            </div>
          </div>
        </Link>
        <Divider style={{ background: "#6c6c6c" }} />
        <Link to={"/donations/mailback-dashboard"} onClick={() => AnnualHandler("mailback-dashboard")}>
          <div className={activeSelection === "mailback-dashboard" ? classes.active : classes.default}>
            <div className={classes.itemContainer}>
              <span>📫</span>
              <span> Mail Dashboard</span>
            </div>
          </div>
        </Link>
        <Divider style={{ background: "#6c6c6c" }} />
        <Link to={"/donations/fake-dashboard"} onClick={() => AnnualHandler("fake-dashboard")}>
          <div className={activeSelection === "fake-dashboard" ? classes.active : classes.default}>
            <div className={classes.itemContainer}>
              <span>🤪</span>
              <span> Fake Dashboard</span>
            </div>
          </div>
        </Link>
        <Divider style={{ background: "#6c6c6c" }} />
        <Link to={"/donations/promo-dashboard"} onClick={() => AnnualHandler("promo-dashboard")}>
          <div className={activeSelection === "promo-dashboard" ? classes.active : classes.default}>
            <div className={classes.itemContainer}>
              <span>🔠</span>
              <span>Promo Dashboard</span>
            </div>
          </div>
        </Link>
        <Divider style={{ background: "#6c6c6c" }} />
      </div>
      <div className={classes.itemContainer}>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <span style={{ color: "white", fontWeight: "bold" }}>Stream status</span>
          <Switch {...switchLabel} checked={donationState} onChange={handleDonationStateChange} />
          <span style={{ color: "white" }}>{donationState === true ? "Enabled" : "Disabled"}</span>
        </div>
      </div>
      <Divider style={{ background: "#6c6c6c" }} />
      <div className={classes.itemContainer}>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <span style={{ color: "white", fontWeight: "bold" }}>Sandbox</span>
          <Switch {...switchLabel} checked={sandboxState} onChange={handleSandboxStateChange} />
          <span style={{ color: "white" }}>{sandboxState === true ? "Enabled" : "Disabled"}</span>
        </div>
      </div>
      <div style={{ color: "white" }}>
        <Divider style={{ background: "#6c6c6c" }} />
        {!isAuthenticated && (
          <div className={classes.default} onClick={() => loginWithRedirect({})}>
            Log in
          </div>
        )}
        {isAuthenticated && (
          <div className={classes.default} onClick={() => logout()}>
            Log out
          </div>
        )}
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  root: {
    display: "flex",
    backgroundColor: "#383446",
    justifyContent: "space-between",
    height: "100vh",
    flexDirection: "column",
    width: "320px",
  },

  active: {
    display: "flex",
    backgroundColor: "#673ab7",
    color: "white",
    fontWeight: 800,
    height: "64px",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
  },
  default: {
    display: "flex",
    backgroundColor: "#383446",
    cursor: "pointer",
    fontWeight: 600,
    height: "64px",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
  },
  links: {
    display: "flex",
    flexDirection: "column",
    "& > *": {
      textDecoration: "none",
      color: "white",
    },
  },
  itemContainer: {
    display: "flex",
    flexDirection: "row",
    gap: "16px",
    alignItems: "center",
    justifyContent: "center",
  },
});

export default NavBar;

import { useAuth0 } from '@auth0/auth0-react';
import axios, { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import configuration from '../../configuration';

interface CancelPollingRequest {
    pollId: number;
}

export function useCancelPolling(
    options?: Omit<
        UseMutationOptions<
            void,
            AxiosError,
            CancelPollingRequest
        >,
        'mutationKey' | 'mutationFn'
    >,
) {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(
        async (req: CancelPollingRequest) => {            
            const token = await getAccessTokenSilently();
            axios.post(`${configuration.apiUrl}/polls/polls/${req.pollId}/cancel`, null, {
                headers: {
                    'authorization': `bearer ${token}`
                }
            });

        }, options
    );
}
import { makeStyles, Tab, Tabs } from "@material-ui/core";
import React, { useState } from "react";
import { THIRTY_SECONDS } from "../constants";
import { DonationResponse, DonationType, FilterParams, useFetchDonations } from "../hooks/useFetchDonations";
import VideoController from "./components/VideoController";

const VideoDashboard = () => {
  const classes = useStyles();
  const [tab, setTab] = useState<number>(0);
  const handleTabChange = (event: React.ChangeEvent<{}>, value: number) => {
    setTab(value);
  };

  const [donationResults, setDonationResults] = useState<DonationResponse>();
  const [filterParams, setFilterParams] = useState<FilterParams>({
    page: 1,
    pageSize: 1000,
    status: "Pending",
    sortOrder: "asc",
    type: DonationType.VIDEO,
  });

  const donationsQuery = useFetchDonations(filterParams, {
    refetchInterval: THIRTY_SECONDS,
    onSuccess: (response) => setDonationResults(response),    
    keepPreviousData: false,
  });

  return (
    <div className={classes.container}>
      <Tabs value={tab} onChange={handleTabChange} style={{ marginBottom: "24px" }}>
        <Tab label="Pending" onClick={() => setFilterParams({ ...filterParams, status: "Pending" })} />
        <Tab label="Played" onClick={() => setFilterParams({ ...filterParams, status: "Approved" })} />
        <Tab label="Rejected" onClick={() => setFilterParams({ ...filterParams, status: "Rejected" })} />
      </Tabs>
      <div className={classes.grid}>
        {donationResults?.donations.map((donation) => (
          <VideoController video={donation} key={donation.id} refetch={() => donationsQuery.refetch()} />
        ))}
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "94vh",
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
    gridGap: "48px",
    marginBottom: "32px",
    width: "100%",
    overflowY: "scroll",
    overflowX: "hidden",
    height: "94vh",
  },
});

export default VideoDashboard;

import React, { useEffect, useRef, useState } from "react";

interface Cell {
  originPosition: number;
  originX: number;
  originY: number;
}

const IMAGE_SRC =
  "https://play-lh.googleusercontent.com/IeNJWoKYx1waOhfWF6TiuSiWBLfqLb18lmZYXSgsH1fvb8v1IYiZr5aYWe0Gxu-pVZX3";
const CANVAS_HEIGHT = 1024;
const CANVAS_WIDTH = 1024;

const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

function range(to: number, zeroIndex = false) {
  return [...Array(to).keys()].map((i) => i + (zeroIndex ? 0 : 1));
}

function shuffle<T>(array: T[], percentage: number): T[] {
  const newArray = [...array];
  for (let i = newArray.length - 1; i > 0; i--) {
    const shouldShuffle = Math.ceil(Math.random() * 100 * Date.now()) % 100 < percentage;
    if (shouldShuffle) {
      const j = Math.floor(Math.random() * (i + 1));
      [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
    }
  }
  return newArray;
}

function renderImage(
  ctx: CanvasRenderingContext2D,
  image: HTMLImageElement, 
  cells: number,
  imageGrid: number[],
) {
  let scale = 1;            
  if (image.naturalHeight > image.naturalWidth) {
    scale = CANVAS_HEIGHT / image.naturalHeight;
  } else {
    scale = CANVAS_WIDTH / image.naturalWidth;
  }

  const columnWidth = image.naturalWidth / cells;
  const rowHeight = image.naturalHeight / cells;

  ctx.clearRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);    
  for (let row = 0; row < cells; row++) {
    for (let col = 0; col < cells; col++) {
      const index = col * cells + row;
      const imageSection = imageGrid[index];
      const rowStart = imageSection % cells;
      const colStart = Math.floor(imageSection / cells);                                    
      ctx.drawImage(
        image,
        rowStart * columnWidth,
        colStart * rowHeight,
        columnWidth,
        rowHeight,
        row * columnWidth * scale,
        col * rowHeight * scale,
        columnWidth * scale,
        rowHeight * scale
      );
    }
  }              
}


const Test3 = () => {
  const [shuffleRate, setShuffleRate] = useState<number>(100);
  const [cells, setCells] = useState<number>(10);
  const [url, setUrl] = useState<string>(IMAGE_SRC);  

  const imageRef = useRef<HTMLImageElement>(new Image());
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const imageGrid = useRef<number[]>(range(cells * cells, true));

  useEffect(() => {    
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }

    const context = canvas.getContext('2d');
    if (!context) {
      return;
    }

    const draw = (ctx: CanvasRenderingContext2D, frameCount: number) => {
      if (imageGrid.current && imageRef.current) {        
        renderImage(ctx, imageRef.current, cells, imageGrid.current);  
      }
    }
  
    let frameCount = 0
    let animationFrameId: number;
      
    const render = () => {
      frameCount++
      draw(context, frameCount)
      animationFrameId = window.requestAnimationFrame(render)
    }
    
    render();    
    return () => {
      window.cancelAnimationFrame(animationFrameId)
    }
  }, [cells])

  useEffect(() => {
    imageRef.current.src = url;
  }, [url, cells]);

  const handlePercentageChange = () => {

  }

  const handleURLChange = () => {

  }

  const handleCellChange = () => {

  }

  const handleSolveClick = () => {

  }

  return (
    <div style={{ color: "white", backgroundColor: "black", height: "100vh" }}>
      <div style={{ display: "flex", flexDirection: "row", padding: "48px" }}>
        <div>
          <canvas ref={canvasRef} height={CANVAS_HEIGHT} width={CANVAS_WIDTH} />
        </div>
        <div style={{ display: "flex", flexDirection: "column", padding: "48px" }}>
          <p>Scatter %</p>

          <input type="number" onChange={handlePercentageChange} value={shuffleRate} />

          <p>Cell size</p>
          <input type="number" onChange={handleCellChange} value={cells} />
          <p>URL</p>
          <input type="text" onChange={handleURLChange} value={url} />
          <button onClick={handleSolveClick}>Solve</button>
        </div>
      </div>
    </div>
  );
};

export default Test3;

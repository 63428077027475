import { makeStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useFetchKeyValue } from "../hooks/useKeyValue";
import Battery from "./Battery";
import NuclearBattery from "./NuclearBattery";
import { GetShockResponse, useZap } from "./useGetShocks";

const ShockOverlay = () => {
  const [shock, setShock] = useState<GetShockResponse>();
  const [hasDonation, setDonation] = useState<boolean>(false);
  const [queryEnabled, setQueryEnabled] = useState<boolean>(true);
  const [isReady, setReady] = useState<Boolean>(false);
  const classes = useStyles();
  const shockSound = require("../../assets/shock.mp3");

  const soundClip = useRef<HTMLAudioElement>(new Audio());

  useEffect(() => {
    soundClip.current.src = shockSound;
    soundClip.current.preload = "auto";
  }, [shockSound]);

  const zapMutation = useZap();

  useEffect(() => {
    const handlePlay = () => {
      setDonation(true);
      setTimeout(() => resetShockUi(), 5000);
    };

    const currentSoundClip = soundClip.current;
    currentSoundClip.addEventListener("play", handlePlay);

    return () => {
      currentSoundClip.removeEventListener("play", handlePlay);
    };
  }, []);

  const toggleShockUi = () => {
    zapMutation.mutate();
    soundClip.current.play();
  };

  const renderBatteries = (power?: number) => {
    switch (power) {
      case 5:
        return (
          <div className={classes.batteryContainer}>
            <Battery enabled />
            <Battery enabled={false} />
            <Battery enabled={false} />
            <Battery enabled={false} />
            <Battery enabled={false} />
          </div>
        );
      case 6:
        return (
          <div className={classes.batteryContainer}>
            <Battery enabled />
            <Battery enabled />
            <Battery enabled={false} />
            <Battery enabled={false} />
            <Battery enabled={false} />
          </div>
        );
      case 7:
        return (
          <div className={classes.batteryContainer}>
            <Battery enabled />
            <Battery enabled />
            <Battery enabled />
            <Battery enabled={false} />
            <Battery enabled={false} />
          </div>
        );
      case 8:
        return (
          <div className={classes.batteryContainer}>
            <Battery enabled />
            <Battery enabled />
            <Battery enabled />
            <Battery enabled />
            <Battery enabled={false} />
          </div>
        );
      case 11:
        return (
          <div className={classes.batteryContainer}>
            <Battery enabled />
            <Battery enabled />
            <Battery enabled />
            <Battery enabled />
            <NuclearBattery enabled />
          </div>
        );
      default:
        return (
          <div>
            <p>ERROR</p>
          </div>
        );
    }
  };
  const resetShockUi = () => {
    setDonation(false);
    setQueryEnabled(true);
  };

  useFetchKeyValue(
    { key: "SHOCK", method: "pop" },
    {
      refetchInterval: 1000,
      enabled: queryEnabled,
      onSuccess: (response: GetShockResponse) => {
        if (response && response !== undefined) {
          setQueryEnabled(false);
          setShock(response);
          toggleShockUi();
        }
      },
    }
  );

  if (!isReady) {
    return (
      <div className={classes.notReadyDiv}>
        <button className={classes.readyButton} onClick={() => setReady(true)}>
          ⚡SET SHOCK READY⚡
        </button>
      </div>
    );
  }

  if (!hasDonation) {
    return null;
  }

  return (
    <div style={{ overflow: "hidden" }}>
      <audio src={shockSound} autoPlay />
      <div className={classes.animationContainer}>
        <div className={classes.root}>
          <div className={classes.container}>
            <h1 className={classes.titleText}>HACKED BY</h1>
            <p className={classes.text}>{shock?.name}</p>
          </div>
          {renderBatteries(shock?.power)}
        </div>
      </div>
    </div>
  );
};
const useStyles = makeStyles({
  root: {
    width: "1100px",
    height: "240px",
    backgroundColor: "#303034",
    borderRadius: "28px",
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    justifyContent: "space-between",
    padding: "24px",
    overflow: "hidden",
  },
  readyButton: {
    padding: "100px",
    fontSize: "48px",
  },

  notReadyDiv: {
    backgroundColor: "yellow",
    height: "100vh",
    width: "100vw",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  text: {
    color: "white",
    fontSize: "53px",
    whiteSpace: "nowrap",
    textAlign: "center",
    margin: "8px 0px",
    fontFamily: "VT323",
  },
  titleText: {
    color: "white",
    fontSize: "117px",
    textAlign: "center",
    textTransform: "uppercase",
    fontFamily: "VT323",
    margin: 0,
  },
  bolts: {
    width: "117px",
    height: "100%",
    objectFit: "contain",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  batteryContainer: {
    display: "flex",
    flexDirection: "row",
    gap: "9px",
  },

  animationContainer: {
    animation: "$slideDown linear 8s",
    position: "relative",
    animationFillMode: "forwards",
    overflow: "hidden",
  },
  "@keyframes slideDown": {
    "0%": { transform: "translateY(240px)", opacity: 0 },
    "2%,98%": { transform: "translateY(0px)", opacity: 1 },
    "100%": { transform: "translateY(240px)", opacity: 0 },
  },
});

export default ShockOverlay;

import React from 'react';
import { makeStyles } from '@material-ui/core';

interface IProps {
    currentPage: number;
    totalPages: number;
    leftArrowClick: () => void;
    rightArrowClick: () => void;
}

const Pagination = (props: IProps) => {

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <span onClick={props.leftArrowClick} className={classes.arrow} >&lt;</span>
            {props.currentPage} of {props.totalPages}
            <span onClick={props.rightArrowClick} className={classes.arrow}>&gt;</span>
        </div>
    )
};

const useStyles = makeStyles({
    root: {
        display: 'flex',
        justifyContent: 'center',
        margin: '32px 0',
    },
    arrow: {
        margin: '0 8px',
        padding: '0 8px',
        cursor: 'pointer',
    },
});

export default Pagination;
import { CircularProgress, makeStyles } from "@material-ui/core";
import CardMedia from "@material-ui/core/CardMedia";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { useFetchDonation, ViewerData } from "../hooks/useFetchDonation";
import { getOrientation } from "../image";

const Viewer = () => {
  // document.designMode = "on";
  const classes = useStyles();
  const [donation, setDonation] = useState<ViewerData>();

  const { token } = useParams() as { token: string };

  const fetchImg = async (url: string) => {
    const data = await fetch(url);
    const blob = await data.blob();
    const file = new File([blob], "template.jpg", blob);
    getOrientation(file, (orientation: number) => {
      console.log(orientation);
    });
  };

  useFetchDonation(
    { token: token },
    {
      onSuccess: (response) => {
        setDonation(response);
        fetchImg(response.media.url);
      },
    }
  );

  if (!donation) {
    return <CircularProgress />;
  }

  return (
    <div className={classes.root}>
      <Helmet>
        <style type="text/css">{"body { background-color: #FFF; }"}</style>
      </Helmet>
      {donation && (
        <div>
          <div className={classes.textContainer}>
            <h1 className={classes.name}>{donation?.name}</h1>
            <h2 className={classes.imageMessage}>{donation?.message}</h2>
          </div>
          {donation?.media ? <CardMedia className={classes.media} image={donation?.media?.url} /> : <p></p>}
          {donation.isMailback && <div className={classes.uuid}>{donation.id}</div>}
        </div>
      )}
    </div>
  );
};

const useStyles = makeStyles({
  root: {
    fontFamily: "Sans-Serif",
    // fontWeight: 400,
    margin: "0 auto",
    color: "black",
    textAlign: "center",
    pageBreakAfter: "avoid",
    pageBreakBefore: "avoid",
    display: "flex",
    flexDirection: "column",
    width: "800px",
    overflow: "hidden",
    height: "1045px",
    justifyContent: "center",
    alignItems: "center",
    // border: "1px solid black",
  },
  textContainer: {
    height: "360px",
    width: "800px",
    overflow: "hidden",
  },
  media: {
    // margin: "0 auto",
    width: "100%",
    height: "665px",
    backgroundSize: "contain",
  },
  name: {
    fontSize: "60px",
    textTransform: "uppercase",
    margin: 0,
    fontWeight: 700,
  },
  donation: {
    fontSize: "30px",
    margin: 0,
    marginBottom: "32px",
  },
  imageMessage: {
    fontSize: "35px",
    margin: 0,
    marginBottom: "32px",
  },
  justMessage: {
    fontSize: "56px",
    margin: 0,
    marginBottom: "32px",
  },
  flourish: {
    width: "50%",
    marginTop: "24px",
  },
  flourishFlipped: {
    width: "50%",
    marginTop: "24px",
    transform: "scaleY(-1)",
  },
  uuid: {
    display: "flex",
    justifyContent: "center",
    fontSize: "10px",
  },
});

export default Viewer;

import { useAuth0 } from '@auth0/auth0-react';
import axios, { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import configuration from '../../configuration';

export interface UpdateStatusRequest {
    id: number;
    name?: string;
    status: string;
}

export function useUpdateStatus(
    options?: Omit<
        UseMutationOptions<
            void,
            AxiosError,
            UpdateStatusRequest
        >,
        'mutationKey' | 'mutationFn'
    >,
) {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(
        async (request: UpdateStatusRequest) => {
            const token = await getAccessTokenSilently();
            await axios.post(`${configuration.apiUrl}/donations/${request.id}/status`, { status: request.status, name:  request.name }, {
                headers: {
                    'authorization': `bearer ${token}`
                }
            });

        }, options
    );
}
import { useAuth0 } from '@auth0/auth0-react';
import axios, { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import configuration from '../../configuration';


export function useExportCSV(
    options?: Omit<
        UseMutationOptions<
            Blob,
            AxiosError,
            number[]
        >,
        'mutationKey' | 'mutationFn'
    >,
) {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(
        async (request: number[]) => {            
            const token = await getAccessTokenSilently();
            const { data } = await axios.post<Blob>(`${configuration.apiUrl}/donations/export`, request, {
                headers: {
                    'authorization': `bearer ${token}`
                }
            });
            return data;
        }, options
    );
}
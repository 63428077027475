import { makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { PollResult, useFetchPollResult } from '../hooks/useFetchPollResult';
import { PollStatus } from '../hooks/useFetchPolls';
import ImageVoteComponent from './components/imageVote';
import TextVoteComponent from './components/textVote';



const PollOverlay = () => {

    const classes = useStyles();
    const [pollResult, setPollResult] = useState<PollResult>();

    useFetchPollResult({
        refetchInterval: 1000,
        onSuccess: (response) => {
            setPollResult(response);
        }
    })

    const getStatusRemap = (status?: PollStatus) => {
        if (status === undefined) {
            return "...Loading";
        }
        switch (status) {
            case PollStatus.QUEUE:
                return 'Get ready voting is about to start'
            case PollStatus.READY:
                return 'Get ready voting is about to open'
            case PollStatus.DONE:
                return 'Vote complete'
            case PollStatus.IN_PROGRESS:
                return 'Vote now'
            case PollStatus.ARCHIVED:
                return 'this is in archived'
        }

    }

    return (
        <div className={classes.root}>
            <div className={classes.blackBarQuestion}>
                <p className={classes.questionText}>{pollResult?.question}</p>
            </div>
            <p className={classes.statusText}>
                {getStatusRemap(pollResult?.status)}
            </p>
            <div className={classes.blackBarResponses}>
                {pollResult?.votes.map((votes) => (
                    votes.image_url === null ? <TextVoteComponent vote={votes} /> : <ImageVoteComponent vote={votes} />
                ))}
            </div>
        </div>
    );
};


const useStyles = makeStyles({
    root: {
        width: '3840px',
        height: '2160px',
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
    },
    blackBarResponses: {
        width: '3840px',
        height: '1006px',
        background: 'rgba(0, 0, 0, 0.4)',
        display: 'flex',
        flexDirection: 'row',
        position: 'absolute',
        bottom: 0,
        justifyContent: 'center',
    },

    blackBarQuestion: {
        width: '100%',
        height: '220px',
        background: 'rgba(0, 0, 0, 0.4)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },

    responseContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '959px',
        justifyContent: 'center',
        alignItems: 'center',
    },
    metaBox: {
        width: '100%',
        height: '95px',
        position: 'absolute',
        bottom: 0,
        borderTop: '24px solid #E7BD10',
        display: 'flex',
        flexDirection: 'row',
        background: 'rgba(0, 0, 0, 0.7)',
    },
    numberBox: {
        display: 'flex',
        backgroundColor: '#E7BD10',
        width: '119px',
        justifyContent: 'center',
        alignItems: 'center',

    },
    votesBox: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    imageFrame: {
        height: '652px',
        width: '652px',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#E7BD10',
        justifyContent: 'center',
        alignItems: 'center',
        filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
    },

    questionText: {
        textAlign: 'center',
        fontSize: '107px',
        color: '#E7BD10',
        margin: 0,
        textTransform: 'uppercase',
        fontFamily: 'Open Sans',
    },
    valueText: {
        textAlign: 'center',
        fontSize: '107px',
        color: '#E7BD10',
        margin: '0',
        marginBottom: '24px',
        textTransform: 'uppercase',
        fontFamily: 'Open Sans',
    },
    optionText: {
        textAlign: 'center',
        fontSize: '66px',
        margin: '0',
        textTransform: 'uppercase',
        fontFamily: 'Open Sans',
    },
    votesText: {
        textAlign: 'center',
        fontSize: '40px',
        margin: '0',
        fontFamily: 'Open Sans',
        color: '#E7BD10',
    },
    statusText: {
        textAlign: 'center',
        fontSize: '40px',
        margin: '0',
        fontFamily: 'Open Sans',
        color: '#E7BD10',
        textTransform: 'uppercase',
        backgroundColor: 'black',
    },



});

export default PollOverlay; 
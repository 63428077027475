import { Button, makeStyles } from "@material-ui/core";
import React from "react";
import CopyToClipboardButton from "./utils/copyToClipboard";

const LandingPage = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <h1>The Ark Admin</h1>
      <div className={classes.container}>
        <h3>Tools</h3>
        <div className={classes.grid}>
          <Button variant="contained" color="primary" href="/donations">
            Donation Dashboard
          </Button>
          <Button variant="contained" color="primary" href="/scheduler">
            Show Scheduler
          </Button>
          <Button variant="contained" color="primary" href="/livedonations/controller">
            Live-count Controller
          </Button>
        </div>
        <h3>Overlays</h3>
        <div className={classes.grid}>
          <CopyToClipboardButton
            value={`${window.location.origin}/livedonations/overlay`}
            name="Live donation overlay"
          />
          <CopyToClipboardButton value={`${window.location.origin}/voicemessages`} name="Voice overlay" />
          <CopyToClipboardButton value={`${window.location.origin}/shockoverlay`} name="Shock overlay" />
          <CopyToClipboardButton value={`${window.location.origin}/videooverlay`} name="Video overlay" />
          <CopyToClipboardButton value={`${window.location.origin}/credits`} name="Credits" />
          <CopyToClipboardButton
            value="http://admin.richardburnish.org/communityboard/overlay"
            name="Community Board"
          />
          <CopyToClipboardButton value={`${window.location.origin}/test`} name="What it is overlay" />
        </div>
        <h3>BeatOff</h3>
        <div className={classes.grid}>
          <Button variant="contained" color="primary" href="/polls">
            Beatoff Dashboard
          </Button>
          <CopyToClipboardButton value={`${window.location.origin}/beatoffversusoverlay`} name="Beat-Off VS Overlay" />
          <CopyToClipboardButton
            value={`${window.location.origin}/beatoffvoteoverlay`}
            name="Beat-Off Voting Overlay"
          />
          <CopyToClipboardButton
            value={`${window.location.origin}/beatoffwinneroverlay`}
            name="Beat-Off Winner Overlay"
          />
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#1b1537",
    height: "100vh",
    width: "100vw",
    color: "white",
  },

  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "24px",
    padding: "24px",
  },

  grid: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gridGap: "48px",
  },
});

export default LandingPage;

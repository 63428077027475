import React, { useState } from "react";
import { Credit, useFetchCredits } from "./useGetCredits";
import { makeStyles } from "@material-ui/core";
import TOSLOGO from "../assets/TosTv3Logo.png";

const Credits = () => {
  const [credits, setCredits] = useState<Credit[]>([]);
  const classes = useStyles();

  useFetchCredits({
    onSuccess: (response) => {
      setCredits(response);
    },
  });
  const truncate = (str: string, n: number) => {
    if (str.length <= n) {
      return str;
    }
    return str.slice(0, n) + "...";
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        {credits.length !== 0 ? (
          <div className={classes.animationContainer}>
            <h1 className={classes.titleText}>The Ark thanks</h1>
            <h1 className={classes.subTitleText}>Control Room Team</h1>
            <p className={classes.text}>Patrick, Big B(RIP)</p>
            <h1 className={classes.subTitleText}>Cho'sen 1</h1>
            <p className={classes.text}>Patrick</p>
            <h1 className={classes.subTitleText}>YouTube Team</h1>
            <p className={classes.text}>The Bird, Dendy, Monica, Ryan, Shah, Assgoblin</p>
            <h1 className={classes.subTitleText}>TWITCH Team</h1>
            <p className={classes.text}>The Bird, Toad, KaptainKerplunk, Znie</p>
            <h1 className={classes.subTitleText}>Social Media Team</h1>
            <p className={classes.text}>Kudo, Toad</p>
            <h1 className={classes.subTitleText}>Music</h1>
            <p className={classes.text}>Richard's Plan - Written and Recorded by Dirty Ol Skunk</p>
            <p className={classes.text}>Faith - Written and Recorded by Swill</p>
            <p className={classes.text}>Montclair Prayer - Written and Recorded by Swill</p>
            <p className={classes.text}>Another Sinful Christmas - Written and Recorded by Swill</p>
            <p className={classes.text}>The Frog - Written and Recorded by Daniel Johns</p>
            <p className={classes.text}>Burnish 16:19 - Written by Richard Burnish Recorded by James Hetfield</p>
            <h1 className={classes.subTitleText}>Production Assistants</h1>
            <div className={classes.textContainer}>
              {credits.map((c) => (
                <p className={classes.text}>{truncate(c.name, 25)}</p>
              ))}
            </div>
            <div className={classes.imageContainer}>
              <img width="600px" src={TOSLOGO} alt="" />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
const useStyles = makeStyles({
  root: {
    width: "100vw",
    height: "100vh",
    backgroundColor: "#2300E7",
    overflow: "hidden",
  },
  text: {
    color: "white",
    fontSize: "2em",
    whiteSpace: "nowrap",
    textAlign: "center",
    margin: "8px 0px",
    fontFamily: "VT323",
  },
  titleText: {
    color: "white",
    fontSize: "4em",
    textAlign: "center",
    textTransform: "uppercase",
    fontFamily: "VT323",
  },
  subTitleText: {
    color: "white",
    fontSize: "3em",
    textAlign: "center",
    textTransform: "uppercase",
    fontFamily: "VT323",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  imageContainer: {
    marginTop: "80px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  animationContainer: {
    marginTop: "100vh",
    animation: "$slideDown linear 230s",
    position: "relative",
  },
  "@keyframes slideDown": {
    from: { transform: "translateY(0px)" },
    to: { transform: "translateY(-1700vh)" },
  },
  textContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    width: "100%",
    gapGap: "24px",
    justifyContent: "center",
  },
});

export default Credits;

import { Button, makeStyles, MenuItem, Select, Snackbar, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import React, { useRef, useState } from 'react';
import { ChromePicker } from 'react-color';
import { Controller, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { Schedule, useFetchSchedule } from './hooks/useFetchSchedule';
import { useSaveSchedule } from './hooks/useSaveSchedule';
import Overlay from './Overlay';

interface Form {
    schedule: Row[];
}

type Row = {
    name: string;
    timer: number;
    colorCode: string;
}

const timerIntervals = [1, 5, 10, 15, 20, 25, 30, 35, 40, 45, 55]

const defaultValues: Form = {
    schedule: [{
        name: '',
        timer: 1,
        colorCode: '',
    }]
};

const Scheduler = () => {
    const classes = useStyles();
    const rowRefs = useRef<HTMLTableRowElement[]>([]);

    const [openAlert, setOpenAlert] = useState<boolean>(false);
    const [showOverlay, setShowOverlay] = useState<boolean>(false);
    const [segments, setSegments] = useState<Schedule[]>([]);
    const [color, setColor] = useState<string>("#000");
    const [fieldIndex, setFieldIndex] = useState<number>();
    const [showColorPicker, setShowColorPicker] = useState<boolean>(false);
    const [timerTotal, setTimerTotal] = useState<number>(0);

    const { control, getValues, handleSubmit, reset, setValue } = useForm<Form>({
        defaultValues,
    });

    const { append, fields, remove, swap } = useFieldArray({
        control,
        name: 'schedule'
    });


    useFetchSchedule({
        onSuccess: (data) => {
            const showTotal = () => {
                const { schedule }: { schedule: Row[] } = getValues() as any;
                const result = schedule.reduce<number>((total, field) => (Number.parseInt(field.timer as unknown as string) || 0) + total, 0);
                setTimerTotal(result);
            }
            reset({ schedule: data });
            showTotal();
        }
    })

    const saveScheduleMutation = useSaveSchedule({
        onSuccess: () => setOpenAlert(true)
    });

    const handleAddRow = () => {
        append(defaultValues.schedule);
    }

    const handleDelete = (index: number) => () => {
        remove(index);
    }

    const handleUp = (index: number) => () => {
        if (index !== 0) {
            swap(index, index - 1);
        }

    }

    const handleDown = (index: number) => () => {
        if (index !== fields.length - 1) {
            swap(index, index + 1);
        }
    }

    const onSubmit: SubmitHandler<Form> = (data) => {
        const segments = data.schedule.map((s) => ({
            name: s.name,
            timer: s.timer,
            colorCode: s.colorCode
        }));

        setSegments(segments);
        setShowOverlay(true);

    }


    const handleSave = () => {
        var { schedule }: { schedule: Row[] } = getValues() as any;

        var data = schedule.map<Schedule>((s, index) => ({
            name: s.name,
            timer: s.timer,
            colorCode: s.colorCode,
            seq: index,
        }))
        saveScheduleMutation.mutate(data);
    }

    const handleCloseAlert = () => {
        setOpenAlert(false);
    }

    const handleColorPickerChange = ({ hex }: { hex: string }) => {
        setColor(hex);
        if (fieldIndex !== undefined) {
            setValue(`schedule.${fieldIndex}.colorCode` as any, hex);
            rowRefs.current[fieldIndex].style.backgroundColor = hex;
        }
    }

    return (
        <div className={classes.root} >
            {showOverlay && (
                <Overlay
                    segments={segments}
                    setShowOverlay={setShowOverlay}
                />
            )}
            <form onSubmit={handleSubmit(onSubmit)} >
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Run Time</TableCell>
                            <TableCell>Color Code</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {fields.map((field, idx) =>
                            <TableRow
                                ref={(elem) => { if (elem != null) rowRefs.current[idx] = elem; }}
                                key={field.id}
                                style={{ backgroundColor: field.colorCode }}
                            >
                                <TableCell onClick={handleUp(idx)} ><Button variant="contained" >UP</Button></TableCell>
                                <TableCell onClick={handleDown(idx)}><Button variant="contained" >DOWN</Button></TableCell>

                                <TableCell>
                                    <Controller
                                        name={`schedule.${idx}.name`}
                                        control={control}
                                        rules={{
                                            required: true,
                                        }}
                                        render={({ field }) => <TextField {...field} />}
                                    />

                                </TableCell>
                                <TableCell>
                                    <Controller
                                        name={`schedule.${idx}.timer`}
                                        control={control}
                                        rules={{
                                            required: true,
                                        }}
                                        render={({ field }) => <Select
                                            {...field}
                                            onClick={() => {
                                                setFieldIndex(idx);
                                                setShowColorPicker(true);
                                            }}

                                        >
                                            {timerIntervals.map((t) => <MenuItem key={t} value={t} >{t}</MenuItem>)}
                                        </Select>
                                        } />
                                </TableCell>
                                <TableCell>
                                    <Controller
                                        name={`schedule.${idx}.colorCode`}
                                        control={control}
                                        rules={{
                                            required: true,
                                        }}
                                        render={({ field }) => <TextField
                                            {...field}
                                            onClick={() => {
                                                setFieldIndex(idx);
                                                setShowColorPicker(true);
                                            }}

                                        />
                                        } />

                                </TableCell>
                                <TableCell onClick={handleDelete(idx)} >
                                    <Button variant="contained" color="secondary" >DELETE</Button>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                <div className={classes.actions} >
                    <div>
                        <Button variant="contained" onClick={handleAddRow} color="primary" >Add</Button>
                    </div>
                    <div>
                        <Button variant="contained" type="submit" color="primary" >Start {timerTotal} Minute Show</Button>
                    </div>
                    <div>
                        <Button variant="contained" onClick={handleSave} type="button" color="secondary" >Save</Button>
                    </div>
                </div>
            </form>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={openAlert}
                autoHideDuration={6000}
                onClose={handleCloseAlert}
                message="Schedule has been updated!"
            />


            {showColorPicker && (
                <div className={classes.popover} onClick={() => setShowColorPicker(false)} >
                    <div className={classes.cover} onClick={(event) => event.stopPropagation()} >
                        <ChromePicker
                            color={color}
                            onChange={handleColorPickerChange}
                        />

                    </div>
                </div>
            )}



        </div >
    );

};

const useStyles = makeStyles({
    root: {
        position: 'fixed',
        height: '100%',
        width: '100%',
        zIndex: 100,
        top: 0,
        left: 0,
    },
    popover: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        zIndex: 2,
    },
    cover: {
        zIndex: 3,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    actions: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '24px',
        '& > div': {
            marginRight: '24px',
        }
    }

});

export default Scheduler; 
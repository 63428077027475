import React from "react";
import { makeStyles } from "@material-ui/core";
import tapePlayerBG from "../../assets/voicemessagesTapeGrainBackground.png";
import tapePlayer from "../../assets/TapePlayerBlank.gif";

interface Props {
  name: string;
}

const VoiceMessageOverlay = (props: Props) => {
  const classes = useStyles();
  const { name } = props;

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <img
          className={classes.imageWaveform}
          src={
            "https://media4.giphy.com/media/ToMjGpJctqrZgKzX0wE/giphy.gif?cid=ecf05e4774ukwax81l0f34ewjezvrir7a3m14dy8427hez5h&rid=giphy.gif&ct=g"
          }
          alt="voice-message"
        />
        <img className={classes.imageTapePlayer} src={tapePlayer} alt="tape-player" />
        <h1 className={classes.nameText}>{name}</h1>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  root: {
    display: "flex",
    padding: "10px",
    width: "382px",
    height: "492px",
  },

  container: {
    display: "flex",
    flexDirection: "column",
    width: "382px",
    height: "492px",
    backgroundImage: "url(" + tapePlayerBG + ")",
  },
  imageWaveform: {
    mixBlendMode: "difference",
    borderRadius: "30px",
    height: "112px",
    width: "100%",
    objectFit: "cover",
    marginTop: "11px",
  },
  imageTapePlayer: {
    borderRadius: "20px",
    filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
  },

  nameText: {
    margin: 0,
    textAlign: "center",
    fontSize: "26px",
    color: "#132F25",
    width: "277px",
    position: "absolute",
    top: "203px",
    left: "61px",
    overflow: "hidden",
    height: "40px",
  },
});

export default VoiceMessageOverlay;

import { Button, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { useQueryClient } from "react-query";
import { useAssignPromoCode } from "./hooks/useAssignPromoCodes";
import { PromoCode, useFetchPromoCodes } from "./hooks/useFetchPromoCodes";
import { useGeneratePromoCodes } from "./hooks/useGeneratePromoCodes";
import CopyToClipboardButton from "../../utils/copyToClipboard";

const PromoCodes = () => {
  const [promoCodes, setPromoCodes] = useState<PromoCode[]>([]);
  const classes = useStyles();

  const queryClient = useQueryClient();

  useFetchPromoCodes({
    onSuccess: (data) => setPromoCodes(data),
  });

  const generatePromoCodesMutation = useGeneratePromoCodes({
    onSuccess: () => {
      queryClient.invalidateQueries("promo-codes");
    },
  });

  const assignPromoCodeMutation = useAssignPromoCode({
    onSuccess: () => {
      queryClient.invalidateQueries("promo-codes");
    },
  });

  const handleGeneratePromoCodes = async () => {
    generatePromoCodesMutation.mutate({ quantity: 10 });
  };

  const handleAssignPromoCode = (promoCode: string) => async () => {
    assignPromoCodeMutation.mutate({ promoCode });
  };

  return (
    <div className={classes.container}>
      <div className={classes.grid}>
        {promoCodes.map((pc) => (
          <div className={classes.item}>
            <CopyToClipboardButton name={pc.id} value={pc.id} />
            <Button onClick={handleAssignPromoCode(pc.id)} color="primary" variant="contained" size="large">
              Reserve
            </Button>
          </div>
        ))}
      </div>
      <Button onClick={handleGeneratePromoCodes} color="primary" variant="contained" size="large">
        Generate More Codes
      </Button>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
    gridGap: "16px",
    marginBottom: "32px",
  },
  item: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridGap: "8px",
    backgroundColor: "#ffffff",
    padding: "16px",
    borderRadius: "4px",
    alignItems: "center",
    fontWeight: "bold",
    fontFamily: "roboto",
  },
  butongroup: {
    marginBottom: "300px",
    width: "100%",
  },
});

export default PromoCodes;

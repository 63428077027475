import { makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Axios from "axios";
import React, { useEffect } from "react";
import configuration from "../configuration";

const TextToSpeech = () => {
  const [voice, setVoice] = React.useState<string>("Whisper");
  const [text, setText] = React.useState<string>("");
  const [submitDisabled, setSubmitDisabled] = React.useState<boolean>(false);

  const classes = useStyles();

  const voices = [
    // "Agnes",
    "Albert",
    // "Alex",
    // "Alice",
    // "Alva",
    // "Anna",
    "Bahh",
    // "Bruce",
    // "Carmit",
    "Daniel",
    "Deranged",
    // "Mei-Jia",
    // "Melina",
    // "Milena",
    // "Moira",
    // "Ralph",
    // "Samantha",
    "Tessa",
    // "Ting-Ting",
    // "Veena",
    // "Vicki",
    // "Victoria",
    "Whisper",
    "Yuna",
  ];

  useEffect(() => {}, []);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setVoice(event.target.value as string);
  };

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
  };

  const handleSubmit = () => {
    const payload = {
      text: text,
      voice: voice,
    };

    setSubmitDisabled(true);
    Axios.post(`${configuration.apiUrl}/say`, payload).then(() => {
      setText("");
      setTimeout(() => setSubmitDisabled(false), 12000);
    });
  };

  return (
    <div className={classes.root}>
      <Paper elevation={3}>
        <div className={classes.innerDiv}>
          <h1>Inmate Chatter</h1>
          <p>Enter a message to make an inmate say it</p>
          <FormControl className={classes.formControl}>
            <InputLabel id="voice">Inmate voice</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select"
              value={voice}
              onChange={handleChange}
              fullWidth
            >
              {voices.map((voice) => (
                <MenuItem value={voice}>{voice}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <TextField
              id="message"
              onChange={handleTextChange}
              inputProps={{ maxLength: 150 }}
              label="Message"
              multiline
              rows={4}
              variant="outlined"
              value={text}
              fullWidth
            />
          </FormControl>
          <Button
            className={classes.button}
            disabled={submitDisabled}
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => handleSubmit()}
          >
            Say as {voice}
          </Button>
        </div>
      </Paper>
    </div>
  );
};

const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "grey",
    height: "100vh",
  },
  innerDiv: {
    width: "320px",
    padding: "24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  formControl: {
    width: "100%",
    marginTop: "32px",
  },
  button: {
    marginTop: "48px",
  },
});

export default TextToSpeech;

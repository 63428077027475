import { makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { GetVideoResponse, useGetVideo } from "../useGetVideo";
import VideoCallModal from "./components/VideoCallModal";
import VideoPlayer from "./components/VideoPlayer";

export type VIDEO_STATES = "GET_READY" | "NEXT_VIDEO" | "PENDING" | "ANSWER" | "REJECT";

const VideoOverlay = () => {
  const [videoCallState, setVideoCallState] = useState<VIDEO_STATES>("GET_READY");
  const [video, setVideo] = useState<GetVideoResponse>();
  const classes = useStyles();

  useGetVideo({
    refetchInterval: 3000,
    enabled: videoCallState === "NEXT_VIDEO",
    onSuccess: (response) => {
      if (response) {
        setVideo(response);
        setVideoCallState("PENDING");
      }
    },
  });

  if (videoCallState === "GET_READY") {
    return (
      <div className={classes.notReadyDiv}>
        <button className={classes.readyButton} onClick={() => setVideoCallState("NEXT_VIDEO")}>
          🎥 SET VIDEO READY 🎥
        </button>
      </div>
    );
  }

  if (!video) {
    return null;
  }

  return (
    <div className={classes.root}>
      <div>
        <div className={classes.videoStage}>
          {videoCallState === "PENDING" ? <VideoCallModal name={video.name} setState={setVideoCallState} /> : null}
          <VideoPlayer videoState={videoCallState} videoUrl={video.url} setState={setVideoCallState} />
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  root: {
    margin: "0",
    width: "600px",
  },
  readyButton: {
    padding: "100px",
    fontSize: "48px",
  },

  notReadyDiv: {
    backgroundColor: "blue",
    height: "100vh",
    width: "100vw",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  videoStage: {
    height: "inherit",
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
  },
});

export default VideoOverlay;

import { makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import GHC from '../../assets/ghc.png';
import WOOD from '../../assets/wood.png';
import { useFetchPollResult, Vote } from '../../polls/hooks/useFetchPollResult';


const WinnerOverlay = () => {

    const classes = useStyles();
    const [winner, setWinner] = useState<Vote>();

    useFetchPollResult({
        refetchInterval: 2000,
        onSuccess: (response) => {
            const winner = response.votes.reduce((p, c) => p.percentage > c.percentage ? p : c);
            setWinner(winner);
        }
    });

    if (winner === undefined) {
        return <div className={classes.rootLoading}>
            <img className={classes.loadingImage} alt="loading" />
        </div>
    }

    return (
        <div className={classes.root}>
            <div className={classes.colContainer}>
                <img className={classes.titleImage} src={GHC} alt="title" />
                <div className={classes.cardContainer}>
                    <img className={classes.cardImage} src={winner.image_url} alt="winner" />
                    <div className={classes.nameContainer}>
                        <h1 className={classes.nameText}>{winner.value}</h1>
                    </div>
                </div>
            </div>
        </div>
    );
};


const useStyles = makeStyles({

    rootLoading: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '3840px',
        height: '2160px',
        color: '#132F25',
        backgroundImage: "url(" + WOOD + ")"
    },
    loadingImage: {
        width: '100%',
    },
    root: {
        width: '3840px',
        height: '2160px',
        color: '#132F25',
        backgroundImage: "url(" + WOOD + ")"
    },
    rowContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    colContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    cardContainer: {
        width: '1407px',
        height: '1407px',
        background: 'linear-gradient(180deg, #FAB92D 0%, #FE6C47 100%)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    cardImage: {
        width: '1359px',
        height: '1359px',
        objectFit: 'cover',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        marginTop: '24px',
    },
    nameContainer: {
        width: "100%",
        height: "200px",
        background: 'linear-gradient(180deg, #FAB92D 0%, #FE6C47 100%)',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
    },
    nameText: {
        margin: 0,
        textAlign: 'center',
        fontSize: '118px',
        color: '#132F25',
    },
    titleImage: {
        width: '60%',
    },




});

export default WinnerOverlay; 
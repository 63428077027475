import { Button, makeStyles, TextField } from "@material-ui/core";
import React from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { Ad } from "../../../useCommunityBoard";
import { useUpdateCommunityBoard } from "../../../useUpdateCommunityBoard";

interface Props {
  ad: Ad;
}

interface Form {
  itemName: string;
  price: number;
  description: string;
  contactName: string;
  contactPhone: string;
}

const AdController = (props: Props) => {
  const { ad } = props;
  const classes = useStyles();

  const { control, handleSubmit } = useForm<Form>({
    defaultValues: {
      contactName: ad.contactName,
      contactPhone: ad.contactPhone,
      description: ad.description,
      itemName: ad.itemName,
      price: ad.price,
    },
  });

  const queryClient = useQueryClient();

  const communityBoardMutation = useUpdateCommunityBoard(ad.id, {
    onSuccess: () => {
      queryClient.invalidateQueries("community-board");
    },
  });

  const handleApproveClick = () => {
    communityBoardMutation.mutate({
      ...ad,
      status: "APPROVED",
    });
  };

  const handleRejectClick = () => {
    communityBoardMutation.mutate({
      ...ad,
      status: "REJECTED",
    });
  };

  const handleSuspendClick = () => {
    communityBoardMutation.mutate({
      ...ad,
      status: "SUSPENDED",
    });
  };

  const handleDeleteClick = () => {
    communityBoardMutation.mutate({
      ...ad,
      status: "DELETED",
    });
  };

  const handlePendingClick = () => {
    communityBoardMutation.mutate({
      ...ad,
      status: "PENDING",
    });
  };

  const onSubmit: SubmitHandler<Form> = (data) => {
    communityBoardMutation.mutate({
      ...ad,
      contactName: data.contactName,
      contactPhone: data.contactPhone,
      description: data.description,
      itemName: data.itemName,
      price: data.price,
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={classes.root}>
        <img className={classes.image} alt="" src={ad.imageUrl} />

        <div className={classes.formContainer}>
          <Controller
            name="itemName"
            control={control}
            render={({ field }) => (
              <TextField {...field} label="Item name" variant="filled" style={{ backgroundColor: "white" }} fullWidth />
            )}
          />
          <Controller
            name="price"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Item price"
                variant="filled"
                style={{ backgroundColor: "white" }}
                fullWidth
              />
            )}
          />
          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Item description"
                variant="filled"
                style={{ backgroundColor: "white" }}
                fullWidth
                multiline
                minRows={4}
              />
            )}
          />

          <Controller
            name="contactName"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Contact name"
                variant="filled"
                style={{ backgroundColor: "white" }}
                fullWidth
              />
            )}
          />
          <Controller
            name="contactPhone"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Contact number"
                variant="filled"
                style={{ backgroundColor: "white" }}
                fullWidth
              />
            )}
          />
          <div className={classes.saveButton}>
            <Button color="primary" size="small" variant="contained" fullWidth type="submit">
              Save
            </Button>
          </div>
          <div className={classes.buttonContainer}>
            {ad.status === "PENDING" ? (
              <Button
                onClick={handleApproveClick}
                variant="contained"
                style={{ color: "white", backgroundColor: "green" }}
                fullWidth
              >
                Approve
              </Button>
            ) : null}
            {ad.status === "PENDING" ? (
              <Button onClick={handleRejectClick} fullWidth variant="contained" color="secondary">
                Reject
              </Button>
            ) : null}
            {ad.status === "APPROVED" ? (
              <Button onClick={handleSuspendClick} fullWidth variant="contained" color="secondary">
                Suspend
              </Button>
            ) : null}
            {ad.status === "SUSPENDED" ? (
              <Button
                onClick={handleApproveClick}
                variant="contained"
                style={{ color: "white", backgroundColor: "green" }}
                fullWidth
              >
                Re-Approve
              </Button>
            ) : null}
            {ad.status === "SUSPENDED" ? (
              <Button onClick={handleDeleteClick} fullWidth variant="contained" color="secondary">
                Delete
              </Button>
            ) : null}
            {ad.status === "REJECTED" ? (
              <Button onClick={handleDeleteClick} fullWidth variant="contained" color="secondary">
                Delete
              </Button>
            ) : null}
            {ad.status === "REJECTED" ? (
              <Button
                onClick={handlePendingClick}
                fullWidth
                variant="contained"
                style={{ color: "white", backgroundColor: "green" }}
              >
                Back to Pending
              </Button>
            ) : null}
          </div>
        </div>
      </div>
    </form>
  );
};

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    border: "1px solid #673ab7",
  },

  image: {
    width: "100%",
    objectFit: "cover",
    aspectRatio: "5/4",
  },

  saveButton: {
    // backgroundColor: "rgba(0, 0, 0, 0.62)",
  },

  formContainer: {
    display: "flex",
    backgroundColor: "#383446",
    padding: "24px",
    flexDirection: "column",
    gap: "16px",
  },
  buttonContainer: {
    display: "flex",
    backgroundColor: "#383446",
    flexDirection: "row",
    gap: "16px",
  },
  links: {
    display: "flex",
    flexDirection: "column",
    "& > *": {
      textDecoration: "none",
      color: "white",
    },
  },
  itemContainer: {
    display: "flex",
    flexDirection: "row",
    gap: "16px",
    alignItems: "center",
    justifyContent: "center",
  },
});

export default AdController;

import { Button, makeStyles, TextField } from "@material-ui/core";
import React from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { Donation } from "../../../hooks/useFetchDonations";
import { useUpdateStatus } from "../../../hooks/useUpdateStatus";
import { useQueueVideo } from "../../useQueueVideo";

interface Props {
  video: Donation;
  refetch: () => void;
}

interface Form {
  name: string;
}

const VideController = (props: Props) => {
  const { refetch, video } = props;
  const classes = useStyles();

  const { control, handleSubmit } = useForm<Form>({
    defaultValues: {
      name: video.name,
    },
  });

  const queryClient = useQueryClient();
  const updateStatusMutation = useUpdateStatus({
    onSuccess: () => {
      refetch();
    },
  });

  const queueVideoMutation = useQueueVideo({  
    onSuccess: () => {
      updateStatusMutation.mutate({
        id: video.id,
        status: "Approved",
      });  
    }  
  })

  const handlePlayClick = () => {
    queueVideoMutation.mutate(video.id);
  };

  const handleRejectClick = () => {
    updateStatusMutation.mutate({
      id: video.id,
      status: "Rejected",
    });
  };
  const handleDeleteClick = () => {
    updateStatusMutation.mutate({
      id: video.id,
      status: "Deleted",
    });
  };
  const handlePendingClick = () => {
    updateStatusMutation.mutate({
      id: video.id,
      status: "Pending",
    });
  };

  const onSubmit: SubmitHandler<Form> = (data) => {
    updateStatusMutation.mutate({
      id: video.id,
      status: video.status,
      name: data.name,
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={classes.root}>
        <video className={classes.video} controls src={video.url} />
        <div className={classes.formContainer}>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField {...field} label="Name" variant="filled" style={{ backgroundColor: "white" }} fullWidth />
            )}
          />
          <div className={classes.saveButton}>
            <Button color="primary" size="small" variant="contained" fullWidth type="submit">
              Save
            </Button>
          </div>
          <div className={classes.buttonContainer}>
            {video.status === "Pending" ? (
              <Button
                onClick={handlePlayClick}
                variant="contained"
                style={{ color: "white", backgroundColor: "green" }}
                fullWidth
              >
                Play Live
              </Button>
            ) : null}
            {video.status === "Pending" ? (
              <Button onClick={handleRejectClick} fullWidth variant="contained" color="secondary">
                Reject
              </Button>
            ) : null}
            {video.status === "Approved" ? (
              <Button
                onClick={handlePlayClick}
                fullWidth
                variant="contained"
                style={{ color: "white", backgroundColor: "green" }}
              >
                Play Again
              </Button>
            ) : null}
            {video.status === "Approved" ? (
              <Button onClick={handleDeleteClick} fullWidth variant="contained" color="secondary">
                Delete
              </Button>
            ) : null}
            {video.status === "Rejected" ? (
              <Button
                onClick={handlePendingClick}
                fullWidth
                variant="contained"
                style={{ color: "white", backgroundColor: "green" }}
              >
                Back to Pending
              </Button>
            ) : null}
            {video.status === "Rejected" ? (
              <Button onClick={handleDeleteClick} fullWidth variant="contained" color="secondary">
                Delete
              </Button>
            ) : null}
          </div>
        </div>
      </div>
    </form>
  );
};

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    border: "1px solid #673ab7",
  },

  video: {
    width: "100%",
  },

  saveButton: {
    // backgroundColor: "rgba(0, 0, 0, 0.62)",
  },

  formContainer: {
    display: "flex",
    backgroundColor: "#383446",
    padding: "24px",
    flexDirection: "column",
    gap: "16px",
  },
  buttonContainer: {
    display: "flex",
    backgroundColor: "#383446",
    flexDirection: "row",
    gap: "16px",
  },
  links: {
    display: "flex",
    flexDirection: "column",
    "& > *": {
      textDecoration: "none",
      color: "white",
    },
  },
  itemContainer: {
    display: "flex",
    flexDirection: "row",
    gap: "16px",
    alignItems: "center",
    justifyContent: "center",
  },
});

export default VideController;

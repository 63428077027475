import { makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import LOADINGIMAGE from '../../assets/loading.png';
import WOOD from '../../assets/wood.png';
import { PollResult, useFetchPollResult } from '../../polls/hooks/useFetchPollResult';
import VoteComponent from "./components/VoteComponent";


const VoteOverlay = () => {

    const classes = useStyles();
    const [pollResult, setPollResult] = useState<PollResult>();

    useFetchPollResult({
        refetchInterval: 2000,
        onSuccess: (response) => {
            setPollResult(response);
        }
    });

    if (pollResult === undefined) {
        return <div className={classes.rootLoading}>
            <img className={classes.loadingImage} src={LOADINGIMAGE} alt="loading" />
        </div>
    }
    return (
        <div className={classes.root}>
            <VoteComponent pollResult={pollResult} />
        </div>
    );
};


const useStyles = makeStyles({
    rootLoading: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '3840px',
        height: '2160px',
        color: '#132F25',
        backgroundImage: "url(" + WOOD + ")"
    },
    loadingImage: {
        width: '100%',
    },
    root: {
    },



});

export default VoteOverlay; 
import { Button, makeStyles } from "@material-ui/core";
import React from "react";
import { VIDEO_STATES } from "../..";
import UserPortraitIcon from "../../../../../assets/UserPortraitIcon.png";

interface Props {
  name: string;
  setState: React.Dispatch<React.SetStateAction<VIDEO_STATES>>;
}

const VideoCallModal = (props: Props) => {
  const classes = useStyles();
  const phoneSound = require("../../../../../assets/phoneVibrate.mp3");

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <img alt="" src={UserPortraitIcon} width="126px" style={{ marginBottom: "24px" }} />
        <h1 className={classes.nameText}>{props.name}</h1>
        <h1 className={classes.nameText}>Priest Time</h1>
        <div className={classes.buttonContainer}>
          <Button
            fullWidth
            className={classes.buttonStyle}
            style={{ color: "#9FFE9B" }}
            onClick={() => props.setState("ANSWER")}
          >
            Accept
          </Button>
          <div className={classes.divider} />
          <Button
            fullWidth
            className={classes.buttonStyle}
            style={{ color: "#FF7666" }}
            onClick={() => props.setState("REJECT")}
          >
            Reject
          </Button>
        </div>
      </div>
      <audio src={phoneSound} autoPlay />;
    </div>
  );
};

const useStyles = makeStyles({
  root: {
    width: "600px",
    height: "100vh",
    backgroundColor: "#292929",
    margin: "0",
    color: "white",
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 99,
  },

  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

  nameText: {
    margin: "0px",
    textAlign: "center",
    fontSize: "26px",
    overflow: "hidden",
    fontWeight: "normal",
    textTransform: "capitalize",
  },

  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: "80px",
    gap: "24px",
  },

  buttonStyle: {
    fontSize: "24px",
    textTransform: "capitalize",
    fontWeight: "normal",
  },
  divider: {
    backgroundColor: "red",
    height: "80px",
    borderLeft: "3px solid #4C4C4C",
  },
});

export default VideoCallModal;

import React from "react";
import { Route, Routes } from "react-router-dom";
import BeatOffVersusOverlay from "./beatoff/VersusOverlay";
import BeatOffVoteOverlay from "./beatoff/VoteOverlay";
import BeatOffWinnerOverlay from "./beatoff/WinnerOverlay";
import CommunityBoardDashboard from "./communityboard/Dashboard";
import CommunityBoardOverlay from "./communityboard/Overlay";
import Generic from "./components/Generic";
import { ProtectedRoute } from "./components/ProtectedRouted";
import Credits from "./credits";
import AudioDashboard from "./donations/AudioDashboard/admin";
import Donations from "./donations/Donations";
import FakeDashboard from "./donations/FakeDashboard";
import MailBackDashboard from "./donations/MailBackDaskboard";
import Admin from "./donations/PrinterDashboard/admin";
import Viewer from "./donations/PrinterDashboard/viewer";
import PromoCodes from "./donations/PromoCodes";
import ShockOverlay from "./donations/Shocks/overlay";
import VideoDashboard from "./donations/VideoDashboard";
import VideoOverlay from "./donations/VideoDashboard/Overlay";
import LandingPage from "./landing";
import LiveDonationController from "./livedonations/Controller";
import LiveDonationOverlay from "./livedonations/Overlay";
import Polls from "./polls";
import PollCreator from "./polls/PollCreator/";
import PollOverlay from "./polls/PollOverlay";
import PollWinnerOverlay from "./polls/PollWinnerOverlay";
import Scheduler from "./schedule";
import Test3 from "./test/Test3";
import TTS from "./texttospeech/";
import VoiceMessages from "./voicemessages";

function App() {
  return (
    <Routes>
      <Route element={<ProtectedRoute component={Generic} />}>
        <Route path={"/"} element={<LandingPage />} />
        <Route path="/polls" element={<Polls />} />
        <Route path="/scheduler" element={<Scheduler />} />
        <Route path="/pollcreator" element={<PollCreator />} />
        <Route path="livedonations/controller" element={<LiveDonationController />} />
        <Route path="donations" element={<Donations showNav={true} />}>
          <Route path="promo-dashboard" element={<PromoCodes />} />
          <Route path="printer-dashboard" element={<Admin />} />
          <Route path="mailback-dashboard" element={<MailBackDashboard />} />
          <Route path="fake-dashboard" element={<FakeDashboard />} />
          <Route path="audio-dashboard" element={<AudioDashboard />} />
          <Route path="community-dashboard" element={<CommunityBoardDashboard />} />
          <Route path="video-dashboard" element={<VideoDashboard />} />
        </Route>
      </Route>
      <Route path="printerdonations" element={<Donations showNav={false} />}>
        <Route path=":token" element={<Viewer />} />
      </Route>
      <Route path="/voicemessages" element={<VoiceMessages />} />
      <Route path="/voicemessages/overlay" />
      <Route path="/beatoffvoteoverlay" element={<BeatOffVoteOverlay />} />
      <Route path="/beatoffversusoverlay" element={<BeatOffVersusOverlay />} />
      <Route path="/beatoffwinneroverlay" element={<BeatOffWinnerOverlay />} />
      <Route path="/pollwinneroverlay" element={<PollWinnerOverlay />} />
      <Route path="/communityboard/overlay" element={<CommunityBoardOverlay />} />
      <Route path="/polloverlay" element={<PollOverlay />} />
      <Route path="/livedonations/overlay" element={<LiveDonationOverlay />} />
      <Route path="/videooverlay" element={<VideoOverlay />} />
      <Route path="/tts" element={<TTS />} />
      <Route path="/credits" element={<Credits />} />
      <Route path="/shockoverlay" element={<ShockOverlay />} />
      <Route path="/test" element={<Test3 />} />
    </Routes>
  );
}

export default App;

import { Button, makeStyles } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { Schedule } from './hooks/useFetchSchedule';

interface Props {
    segments: Schedule[];
    setShowOverlay: React.Dispatch<React.SetStateAction<boolean>>;
}

const ONE_SECOND = 1000;
const SECONDS_IN_A_MINUTE = 60;

const Overlay = (props: Props) => {

    const { segments, setShowOverlay } = props;

    const classes = useStyles();
    const timer = useRef<HTMLDivElement>(null);
    const [segmentIndex, setSegmentIndex] = useState<number>(0);
    const paused = useRef<boolean>(false);
    const [completed, setCompleted] = useState<boolean>(false);
    const timerId = useRef<number>();

    const handlePause = () => {
        paused.current = !paused.current;
    }

    useEffect(() => {
        let timerInSeconds = segments[segmentIndex].timer * SECONDS_IN_A_MINUTE;

        if (timer.current) {
            timer.current.innerText = "STARTING...";
        }

        if (timerId.current !== null) {
            clearInterval(timerId.current);
        }

        if (!completed) {
            timerId.current = window.setInterval(() => {
                if (timer.current && !paused.current) {
                    timerInSeconds -= 1;

                    const minutesRemaining = Math.trunc(timerInSeconds / SECONDS_IN_A_MINUTE);
                    const secondsRemaining = String(timerInSeconds - (minutesRemaining * SECONDS_IN_A_MINUTE)).padStart(2, '0');
                    timer.current.innerText = minutesRemaining > 0 ? `${minutesRemaining}:${secondsRemaining}` : `${secondsRemaining}`;

                    if (timerInSeconds <= 1) {
                        if (segmentIndex < segments.length - 1) {
                            setSegmentIndex((state) => state + 1);
                        } else {
                            setCompleted(true);
                        }
                    }
                }
            }, ONE_SECOND);
        }


        return () => {
            clearInterval(timerId.current);
        }
    }, [segmentIndex, completed, segments]);

    const handleBack = () => {
        setSegmentIndex((idx) => Math.max(0, idx - 1));
    }

    const handleForward = () => {
        setSegmentIndex((idx) => Math.min(segments.length - 1, idx + 1));
    }


    const handleClose = () => {
        setShowOverlay(false);
    }

    return (
        <div className={classes.root} style={{ backgroundColor: `${segments[segmentIndex].colorCode}` }} >
            <div className={classes.container} >
                {!completed && (
                    <>
                        <div>{segments[segmentIndex].name}</div>
                        <div ref={timer} ></div>
                        <div className={classes.buttonGroup} >
                            <Button color="primary" variant="contained" onClick={handlePause} >Pause</Button>
                            <Button color="primary" variant="contained" onClick={handleBack} >Back</Button>
                            <Button color="primary" variant="contained" onClick={handleForward} >Forward</Button>
                            <Button color="secondary" variant="contained" onClick={handleClose} >Close</Button>
                        </div>
                    </>
                )}
                {completed && <div>THE END</div>}
            </div>
        </div>
    );


};

const useStyles = makeStyles({
    root: {
        position: 'fixed',
        height: '100%',
        width: '100%',
        zIndex: 100,
        top: 0,
        left: 0,
    },
    container: {
        fontSize: '100px',
        display: 'flex',
        height: '100%',
        width: '100%',
        flexFlow: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        WebkitAlignItems: 'center',
    },
    buttonGroup: {
        marginTop: '40px',
        width: '25%',
        display: 'flex',
        justifyContent: 'space-between',
    },
});

export default Overlay;
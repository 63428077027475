import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import configuration from '../../../configuration';


export function useGetAudioVolume(
    options?: UseQueryOptions<number>
) {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery<number>('audio-volume',
        async () => {
            const token = await getAccessTokenSilently();
            const { data } = await axios.get<number>(`${configuration.apiUrl}/donations/play_audio/volume`, {
                headers: {
                    'authorization': `bearer ${token}`
                }
            });
            return data;
        },
        options
    );
}
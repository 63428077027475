import { useAuth0 } from '@auth0/auth0-react';
import axios from "axios";
import { useQuery, UseQueryOptions } from "react-query";
import configuration from "../../configuration";

export enum PollStatus {
    QUEUE = 'queue',
    READY = 'ready',
    DONE = 'done',
    IN_PROGRESS = 'in_progress',
    ARCHIVE = 'archive',
    ARCHIVED = 'archive',
}

export interface PollOption {
    option: number;
    value: string;
    image_url?: string;
    id: number;
    poll_id: number;
}

export interface Poll {
    video_id: string;
    question: string;
    duration: number;
    started_at?: number;
    status: PollStatus;
    allow_multiple_votes: boolean;
    id: number;
    poll_options: PollOption[];
}

export function useFetchPolls(    
    options?: UseQueryOptions<Poll[]>
) {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery<Poll[]>('polls',
        async () => {
            const token = await getAccessTokenSilently();
            const { data } = await axios.get<Poll[]>(
                `${configuration.apiUrl}/polls/polls`,
                {
                    headers: {
                        'authorization': `Bearer ${token}`
                    }
                });
            return data;
        },
        options);
}
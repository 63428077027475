import { makeStyles } from "@material-ui/core";
import React from "react";
import { Helmet } from "react-helmet";
import { Outlet } from "react-router-dom";
import NavBar from "../components/NavBar";

interface Props {
  showNav: boolean;
}
const Donations = (props: Props) => {
  const classes = useStyles();
  const { showNav } = props;

  return (
    <div>
      <Helmet>
        <style type="text/css">{"body { background-color: #1b1537; }"}</style>
      </Helmet>
      <div className={classes.layout}>
        {showNav ? <NavBar /> : null}
        <div className={classes.root}>
          <div className={classes.container}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  root: {
    fontFamily: "Nunito",
    fontWeight: 400,
    padding: "16px",
    color: "white",
    width: "100%",
  },
  container: {
    // marginTop: "28px",
    display: "flex",
    // maxHeight: "6000px",
    flexDirection: "column",
    width: "100%",
  },
  layout: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
});

export default Donations;

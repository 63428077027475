import { makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { useFetchPollResult, Vote } from '../hooks/useFetchPollResult';
import ImageVoteComponent from '../PollOverlay/components/imageVote';
import TextVoteComponent from '../PollOverlay/components/textVote';


const PollWinnerOverlay = () => {

    const classes = useStyles();
    const [winner, setWinner] = useState<Vote>();

    useFetchPollResult({
        refetchInterval: 2000,
        onSuccess: (response) => {
            if (response.votes.length === 0) {
                return;
            }
            const winner = response.votes.reduce((p, c) => p.percentage > c.percentage ? p : c);
            setWinner(winner);   
        }
    })

    if (winner === undefined) {
        return <div className={classes.rootLoading}>
            <img className={classes.loadingImage} alt="loading" />
        </div>
    }

    return (
        <div className={classes.root}>
            <div className={classes.responseContainer}>
                <h1 className={classes.winnerText}>AND THE WINNER IS...</h1>
                {winner.image_url === null ? <TextVoteComponent vote={winner} /> : <ImageVoteComponent vote={winner} />}
            </div>
        </div>
    );
};


const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'rgba(0, 0, 0, 0.4)',
        border: '1px solid #E7BD10',
        height: '3840px',
        widht: '2160px',
    },
    rootLoading: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '3840px',
        height: '2160px',
        color: '#132F25',
        // backgroundImage: "url(" + WOOD + ")"
    },
    loadingImage: {
        width: '100%',
    },
    blackBarResponses: {
        width: '3840px',
        height: '1006px',
        background: 'rgba(0, 0, 0, 0.4)',
        display: 'flex',
        flexDirection: 'row',
        position: 'absolute',
        bottom: 0,
        justifyContent: 'center',
    },

    blackBarQuestion: {
        width: '100%',
        height: '220px',
        background: 'rgba(0, 0, 0, 0.4)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },

    responseContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '959px',
        justifyContent: 'center',
        alignItems: 'center',
    },
    metaBox: {
        width: '100%',
        height: '95px',
        position: 'absolute',
        bottom: 0,
        display: 'flex',
        flexDirection: 'row',
        background: 'rgba(0, 0, 0, 0.7)',
    },
    numberBox: {
        display: 'flex',
        backgroundColor: '#E7BD10',
        width: '119px',
        justifyContent: 'center',
        alignItems: 'center',

    },
    votesBox: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    imageFrame: {
        height: '95px',
        width: '652px',
        display: 'flex',
        flexDirection: 'column',
        background: 'rgba(0, 0, 0, 0.4)',
        justifyContent: 'center',
        alignItems: 'center',
        filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
    },

    questionText: {
        textAlign: 'center',
        fontSize: '107px',
        color: '#E7BD10',
        margin: 0,
        textTransform: 'uppercase',
        fontFamily: 'Open Sans',
    },
    valueText: {
        textAlign: 'center',
        fontSize: '107px',
        color: '#E7BD10',
        margin: '0',
        marginBottom: '24px',
        textTransform: 'uppercase',
        fontFamily: 'Open Sans',
    },
    optionText: {
        textAlign: 'center',
        fontSize: '66px',
        margin: '0',
        textTransform: 'uppercase',
        fontFamily: 'Open Sans',
    },
    votesText: {
        textAlign: 'center',
        fontSize: '40px',
        margin: '0',
        fontFamily: 'Open Sans',
        color: '#E7BD10',
    },
    winnerText: {
        fontFamily: 'Open Sans',
        color: '#E7BD10',
    }
});

export default PollWinnerOverlay; 
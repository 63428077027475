import { useAuth0 } from '@auth0/auth0-react';
import axios from "axios";
import { useQuery, UseQueryOptions } from "react-query";
import configuration from '../../../configuration';

export interface PromoCode {
    id: string;
}

export function useFetchPromoCodes(
    options?: UseQueryOptions<PromoCode[]>
) {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery<PromoCode[]>('promo-codes',
        async () => {
            const token = await getAccessTokenSilently();
            const { data } = await axios.get<PromoCode[]>(
                `${configuration.apiUrl}/donations/promo_code`,
                {
                    headers: {
                        'authorization': `Bearer ${token}`
                    }
                });
            return data;
        },
        options);
}
import React, { useState } from "react";
import { Tabs, Tab } from "@material-ui/core";

import Done from "./Done";
import Pending from "./Pending";

const MailBackDashboard = () => {
  const [tab, setTab] = useState<number>(0);

  const handleTabChange = (event: React.ChangeEvent<{}>, value: number) => {
    setTab(value);
  };

  return (
    <div>
      <Tabs value={tab} onChange={handleTabChange} style={{ marginBottom: "24px" }}>
        <Tab label="Pending" />
        <Tab label="Done" />
      </Tabs>
      {tab === 0 && <Pending />}
      {tab === 1 && <Done />}
    </div>
  );
};

export default MailBackDashboard;

import { CircularProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React, { useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { THIRTY_SECONDS } from '../constants';
import { DonationResponse, DonationType, FilterParams, useFetchDonations } from '../hooks/useFetchDonations';
import { useUpdateStatus } from '../hooks/useUpdateStatus';
import { useQueueAudio } from './hooks/useQueueAudio';

const statuses = ['Pending', 'Printed', 'Rejected'];

export default function SimpleTable() {
    const classes = useStyles();    
    const audio = useRef<HTMLAudioElement>(new Audio());

    const [donationResults, setDonationResults] = useState<DonationResponse>();
    const [filterParams] = useState<FilterParams>({
        page: 1,
        pageSize: 1000,
        status: 'Pending',
        sortOrder: 'asc',
        type: DonationType.AUDIO,
    })

    const [playingAudio, setPlayingAudio] = useState<number>(0);

    useFetchDonations(filterParams, {
        refetchInterval: THIRTY_SECONDS,
        onSuccess: (response) => setDonationResults(response),
    });


    const handlePlay = (id: number) => () => {
        if (donationResults) {
            const donation = donationResults.donations.find((d) => d.id === id);
            if (donation) {
                if (audio.current.paused) {
                    audio.current.src = donation.url;
                    audio.current.play();
                    setPlayingAudio(donation.id);
                    audio.current.onpause = () => {
                        audio.current.currentTime = 0;
                        setPlayingAudio(0);
                    }
                } else {
                    audio.current.pause();
                }
            }
    
        }
    }

    const queryClient = useQueryClient();
    const updateStatusMutation = useUpdateStatus({
        onSuccess: () => {
            queryClient.invalidateQueries('donations');
        }
    })

    const handleChange = (id: number) => async (event: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>) => {
        updateStatusMutation.mutate({
            id,
            status: event.target.value as string,
        });
    }

    const queueAudioMutation = useQueueAudio({})

    const handleQueue = (id: number) => async () => {
        queueAudioMutation.mutate(id);
    }

    if (!donationResults) {
        return <CircularProgress />
    }


    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell align="right" >Message</TableCell>
                        <TableCell align="right">Donation</TableCell>
                        <TableCell align="right">Image</TableCell>
                        <TableCell align="right">Status</TableCell>
                        <TableCell align="right">Donated At</TableCell>
                        <TableCell align="right">Payment ID</TableCell>
                        <TableCell align="right">Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {donationResults.donations.map((row, idx) => (
                        <TableRow key={row.id}>
                            <TableCell component="th" scope="row">
                                {row.name}
                            </TableCell>
                            <TableCell align="right">{row.message}</TableCell>
                            <TableCell align="right">{row.donation}</TableCell>
                            <TableCell align="right">{row.media}</TableCell>
                            <TableCell align="right">
                                <FormControl>
                                    <Select
                                        name={`donationResults.donations[${idx}].status`}
                                        value={row.action}
                                        onChange={handleChange(row.id)}
                                    >
                                        {statuses.map((status) => (
                                            <MenuItem value={status}>{status}</MenuItem>
                                        ))}

                                    </Select>
                                </FormControl>
                            </TableCell>
                            <TableCell align="right">{new Date(row.createdAt).toLocaleString("en-CA")}</TableCell>
                            <TableCell align="right">{row.paymentId}</TableCell>
                            <TableCell align="right">
                                <Button onClick={handlePlay(row.id)} color="primary" variant="contained">{playingAudio === row.id ? "STOP" : "PREVIEW"}</Button>
                                <Button style={{ marginLeft: '8px' }} onClick={handleQueue(row.id)} color="secondary" variant="contained">PLAY LIVE</Button>
                            </TableCell>

                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

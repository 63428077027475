import { useAuth0 } from "@auth0/auth0-react";
import axios, { AxiosError } from "axios";
import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from "react-query";
import configuration from "../../configuration";

export interface KeyValueRequest {
  key: string;
  method?: "pop" | undefined;
}

export interface SetKeyValueRequest {
  key: string;
  value: string | boolean | number;
}

export function useFetchKeyValue(request: KeyValueRequest, options?: UseQueryOptions<any>) {
  return useQuery<any>(
    ["key-value", request],
    async () => {
      const { data } = await axios.get<any>(`${configuration.apiUrl}/donations/key-value`, {
        params: request,
      });
      return data;
    },
    options
  );
}

export function useSetKeyValue(
  options?: Omit<UseMutationOptions<void, AxiosError, SetKeyValueRequest>, "mutationKey" | "mutationFn">
) {
  const { getAccessTokenSilently } = useAuth0();
  return useMutation(async (request: SetKeyValueRequest) => {
    const token = await getAccessTokenSilently();
    await axios.post(`${configuration.apiUrl}/donations/key-value`, request, {
      headers: {
        authorization: `bearer ${token}`,
      },
    });
  }, options);
}

import { useAuth0 } from '@auth0/auth0-react';
import axios from "axios";
import { useQuery, UseQueryOptions } from "react-query";
import configuration from "../../configuration";


export type Schedule = {
    name: string;
    timer: number;
    colorCode: string;
    seq?: number;
}

export function useFetchSchedule(    
    options?: UseQueryOptions<Schedule[]>
) {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery<Schedule[]>('polls',
        async () => {
            const token = await getAccessTokenSilently();
            const { data } = await axios.get<Schedule[]>(
                `${configuration.apiUrl}/schedule`,
                {
                    headers: {
                        'authorization': `Bearer ${token}`
                    }
                });
            return data;
        },
        options);
}